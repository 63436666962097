import React, {useState, useEffect} from 'react';
import DialogLayout from '../../components/layout/DialogLayout';
import { apiFundReceived, apiFundUransferred, apiInvestorOpportunity, apiTransactionFiles, getDataDictionaryValues, uploadFile } from '../../utils/api/api';
import { BASE_URL, DD_TYPE_TRANSACTION_FILES, SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";
import { useSnackbar } from 'notistack';
import { Autocomplete, Button, IconButton, Stack, Switch, TextField, Tooltip } from '@mui/material';
import { useHistory } from "react-router-dom";
import { toLocalePrice, toLocalePriceNoDecimal } from '../../utils/StringUtil';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AttachFileIcon from '@mui/icons-material/AttachFile';

const TransactionDocuments = ({ open, toggleDialog, scheme, handleSave }) => {
  const [fileCat, setFileCat] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [tranFile, setTranFile] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [actionLoader, setActionLoader] = useState(false);

  const handleAddFile = () => {
    setFileList([...fileList, tranFile]);
    setTranFile({});
  }

  const handleDeleteTransactionFile = (index) => {
    const newList = [...fileList];
    newList.splice(index, 1);
    setFileList(newList);
  }

  const handleAutoCompleteFS = (value, type, index) => {
    value = value.trim();

    if(type === DD_TYPE_TRANSACTION_FILES){
      const newList = [...fileList];
      const selObject = newList[index];
      const ddData = fileCat.find((item) =>
        item.ddValue.trim() === value
      );
      if (ddData) {
        selObject.category = null;
        selObject.categoryDdIndex = ddData.ddIndex;
      } else {
        selObject.category = value;
        selObject.categoryDdIndex = null;
      }
      setFileList(newList);
    }
  }

  const uploadDocument = (e, index) => {
    const newList = [...fileList];
    // setActionLoader(true);
    uploadFile(e.target.files[0])
      .then((res) => {
        // setActionLoader(false);
          const selObject = newList[index];
          const updatedObject = { ...selObject, ...res.data };
          newList[index] = updatedObject;
          setFileList(newList);
      })
      .catch((e) => {
        // setActionLoader(false);
          enqueueSnackbar(e, SNACKBAR_ERROR);
      });
  }

  const getDataDictionary = () => {
    getDataDictionaryValues()
      .then((data) => {
        const files_cat = data.filter((item) =>
          item.ddType === DD_TYPE_TRANSACTION_FILES
        );
        setFileCat(files_cat);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  useEffect(() => {
    if(open){
      getDataDictionary();
    }
    setFileList(scheme.tranFiles ?? []);
    setTranFile({});
  }, [open]);

  const handleDocValidation = () => {
    const newList = fileList.filter(obj => Object.keys(obj).length !== 0);
    const docNameErrors = [];
    const fileErrors = [];

    if(newList.length === 0){
      return {"status": false, "description": "Empty Documents"}
    }

    newList.map(item => {
      const isCategoryNotValid = item?.category === undefined || item?.category === null || item?.category?.trim() === '';
      const isCategoryIndexNotValid = item?.categoryDdIndex === undefined || item?.categoryDdIndex === null;
      const isFileNotValid = item?.path === undefined || item?.path === null;

      if(isCategoryNotValid && isCategoryIndexNotValid){
        docNameErrors.push({"status": false, "description": "Please Enter Document Name"});
      }
      if(isFileNotValid){
        fileErrors.push({"status": false, "description": "Please Upload File"});
      }
    })

    if (docNameErrors.length > 0) {
      return {"status": false, "description": "Please Enter Document Name"};
    }
    if (fileErrors.length > 0) {
      return {"status": false, "description": "Please Upload File"};
    }
    return {"status": true, "description": "Success", "data": newList}
  }

  const handleTransactionFiles = () => {
    const {status, description, data} = handleDocValidation();

    if(status){
      setActionLoader(true);
      apiTransactionFiles({
        "oppRid": scheme.oppRid,
        "tranFiles": data
      })
        .then((data) => {
          setActionLoader(false);
            enqueueSnackbar(data,SNACKBAR_SUCCESS);
            handleSave();
        })
        .catch((error) => {
          setActionLoader(false);
          
        });
    } else {
      enqueueSnackbar(description,SNACKBAR_WARNING);
    }
    
  }

  return (
    <DialogLayout
      open={open}
      title="Transaction Documents"
      maxWidth="sm"
      actionLoader={actionLoader}
      closeAction={toggleDialog}
      negativeAction={
        {label: 'Cancel', onClick : toggleDialog}
      }
      positiveAction={{label: 'Save', onClick : handleTransactionFiles}}
    >
        <Stack sx={{flex: 1}} direction="column" spacing={2}>
          <h3 className="fs-large-semibold text-primary">{scheme.oppTitle}</h3>

          {fileList.map((data, key)=>{
            return(
              <Stack sx={{flex: 1}} direction="row" spacing={2} key={key}>
                <Stack sx={{flex: 1}} direction="row" spacing={2}>
                  <Autocomplete
                    freeSolo
                    sx={{ flex: 1 }}
                    id="outlined-basic"
                    variant="outlined"
                    options={fileCat}
                    getOptionLabel={(option) => option.ddValue}
                    value={fileCat.find((option) => option.ddIndex === data.categoryDdIndex) || null}
                    fullWidth
                    onInputChange={(e, v) => {
                        handleAutoCompleteFS(v, DD_TYPE_TRANSACTION_FILES, key);
                    }}
                    renderInput={(params) => <TextField {...params} label="Doc Name" />}
                  />
                  <Stack sx={{ flexWrap: 'wrap' }} direction="row">
                    <input
                      className="d-none"
                      id={"input-doc-" + key}
                      type="file"
                      accept=".pdf"
                      onClick={(e) => {
                          e.target.value = null;
                      }}
                      onChange={(e) => {
                          uploadDocument(e, key);
                      }}
                    />
                  </Stack>
                  {data.path ? 
                  <Tooltip title={data.actualFileName}>
                    <IconButton color="primary" onClick={()=> window.open(`${BASE_URL}/${data.path}`, '_blank')}>
                      <AttachFileIcon />
                    </IconButton>
                  </Tooltip> : 
                  <IconButton color="primary" onClick={()=>
                    document.getElementById("input-doc-" + key).click()}>
                    <AddIcon />
                  </IconButton> }
                </Stack>
                <IconButton color="error" onClick={() => handleDeleteTransactionFile(key)}>
                  <DeleteOutlineIcon />
                </IconButton>
              </Stack>
            );
          })}

          <Button sx={{width: '100px'}} variant="outlined" startIcon={<AddIcon />} onClick={handleAddFile}>
            Add
          </Button>
        </Stack>
    </DialogLayout>
  );
}

export default TransactionDocuments;