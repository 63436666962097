import React, { useEffect, useState } from "react";
import DialogLayout from "../../components/layout/DialogLayout";
import { Box, CircularProgress, TextField, Button } from "@mui/material";
import { apiChangePartnerPassword } from "../../utils/api/api";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../utils/constants/constants";
import { useSnackbar } from "notistack";

const PartnerPassword = ({ open, toggleDialog, partner }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleChangePassword = () => {
    if (password !== confirmPassword) {
      enqueueSnackbar("Passwords do not match", SNACKBAR_ERROR);
      return;
    }
    setIsLoading(true);
    apiChangePartnerPassword({
      userRid: partner?.userRid,
      password: password,
    })
      .then((data) => {
        setIsLoading(false);
        enqueueSnackbar(data, SNACKBAR_SUCCESS);
        toggleDialog();
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
      
  };

  useEffect(()=>{
    if(open){
      setPassword("");
      setConfirmPassword("");
    }
  },[open])

  return (
    <DialogLayout
      actionLoader={isLoading}
      open={open}
      title="Change Password"
      subtitle={partner?.fullName}
      maxWidth="xs"
      closeAction={toggleDialog}
      positiveAction={{ label: "Save", onClick: handleChangePassword}}
      negativeAction={{ label: "Cancel", onClick: toggleDialog }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="d-flex flex-column">
          <Box>
            <p className="section-label mb-1" style={{ fontSize: "12px" }}>
              New Password
            </p>
            <TextField
              type="password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Box>
          <Box mt={2}>
            <p className="section-label mb-1" style={{ fontSize: "12px" }}>
              Confirm Password
            </p>
            <TextField
              type="password"
              variant="outlined"
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Box>
        </div>
      )}
    </DialogLayout>
  );
};

export default PartnerPassword
