import React, {useState,useEffect} from 'react';
import Layout from '../../components/layout/Layout';
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import UnitTransferModal from './UnitTransferModal';
import { useSnackbar } from 'notistack';
import { RECORD_DISPLAY_LIMIT, SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../utils/constants/constants";
import { apiInvestorOpportunity, apiUnitTransfer, getInvestors, getOpportunity } from '../../utils/api/api';
import { formatDateTime, isSubAdmin, toLocalePrice } from '../../utils/StringUtil';
import { Autocomplete, IconButton, Skeleton, TextField, Tooltip } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const UnitTransfer = () => {
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editUnitTransfer, setEditUnitTransfer] = useState(null);
    const [unitTransferList, setUnitTransferList] = useState([]);
    const [allUnitTransferList, setAllUnitTransferList] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [schemeList, setSchemeList] = useState([]);
    const [sellerList, setSellerList] = useState([]);
    const [buyerList, setBuyerList] = useState([]);
    const [oppRid, setOppRid] = useState(null);
    const [sellerInv, setSellerInv] = useState(null);
    const [buyerInv, setBuyerInv] = useState(null);
    const userType = process.env.REACT_APP_USER_TYPE;  

    const resetFilter = (listItems) => {
        setSchemeList(
            listItems.filter((obj, index, self) =>
                index === self.findIndex((item) => item.oppRid === obj.oppRid)
            )
        );
        setSellerList(
            listItems.filter((obj, index, self) =>
                index === self.findIndex((item) => item.sellerInvRid === obj.sellerInvRid)
            )
        );
        setBuyerList(
            listItems.filter((obj, index, self) =>
                index === self.findIndex((item) => item.buyerInvRid === obj.buyerInvRid)
            )
        );
        setOppRid(null);
        setBuyerInv(null);
        setSellerInv(null);
    }

    const handleOpen = () => {
        setOpen(true);
        setEditUnitTransfer({});
    };

    const handleClose = () => {
        setOpen(false);
        setIsEdit(false);
    };

    const handleSave = () => {
        setPage(1);
        getUnitTransfer();
        handleClose();
    };

    const getAllUnitTransfer = (param = {}) => {
        param.pageNumber = 0;
        apiUnitTransfer(param)
            .then((data) => {
                setAllUnitTransferList(data.unitTransfer);
                resetFilter(data.unitTransfer);
            }).catch((e) => {
                enqueueSnackbar(e,SNACKBAR_ERROR)
            })
    }

    const getUnitTransfer = (param = {}) => {
        param.pageNumber = 0;
        param.oppRid = oppRid;
        param.sellerInvRid = sellerInv?.sellerInvRid;
        param.buyerInvRid = buyerInv?.buyerInvRid;
        // param.name = searchTerm.trim();
        // param.recLimit = RECORD_DISPLAY_LIMIT;
        setIsLoading(true);
        apiUnitTransfer(param)
            .then((data) => {
                setIsLoading(false);
                setUnitTransferList(data.unitTransfer);
                // if(page === 1){
                //   setTotalRecords(data.records);
                // }
            }).catch((e) => {
                setIsLoading(false);
                enqueueSnackbar(e,SNACKBAR_ERROR)
            })
    }

    useEffect(() => {
        if (oppRid !== null) {
            setSellerList(
                allUnitTransferList.filter((obj, index, self) =>
                    index === self.findIndex((item) => item.sellerInvRid === obj.sellerInvRid)
                ).filter((item) => item.oppRid === oppRid)
            );
            setBuyerList(
                allUnitTransferList.filter((obj, index, self) =>
                    index === self.findIndex((item) => item.buyerInvRid === obj.buyerInvRid)
                ).filter((item) => item.oppRid === oppRid)
            );
        }
    }, [oppRid]);

    useEffect(() => {
        if (sellerInv !== null) {
            setSchemeList(
                allUnitTransferList.filter((obj, index, self) =>
                    index === self.findIndex((item) => item.oppRid === obj.oppRid)
                ).filter((item) => item.sellerInvRid === sellerInv?.sellerInvRid)
            );
            setBuyerList(
                allUnitTransferList.filter((obj, index, self) =>
                    index === self.findIndex((item) => item.buyerInvRid === obj.buyerInvRid)
                ).filter((item) => item.sellerInvRid === sellerInv?.sellerInvRid)
            );
        }
    }, [sellerInv]);

    useEffect(() => {
        if (buyerInv !== null) {
            setSellerList(
                allUnitTransferList.filter((obj, index, self) =>
                    index === self.findIndex((item) => item.sellerInvRid === obj.sellerInvRid)
                ).filter((item) => item.buyerInvRid === buyerInv?.buyerInvRid)
            );
            setSchemeList(
                allUnitTransferList.filter((obj, index, self) =>
                    index === self.findIndex((item) => item.oppRid === obj.oppRid)
                ).filter((item) => item.buyerInvRid === buyerInv?.buyerInvRid)
            );
        }
    }, [buyerInv]);

    useEffect(() => {
        getUnitTransfer();
    }, [oppRid, sellerInv, buyerInv]);

    useEffect(() => {
        getAllUnitTransfer();
    }, [page]);

  return (
    <Layout title="Unit Transfer">
        {userType === "ADMIN" && !isSubAdmin() && (
            <Button
            sx={{
                height: "64px",
                borderRadius: "50%",
                position: "fixed",
                bottom: "20px",
                right: "20px",
            }}
            onClick={handleOpen}
            variant="contained"
            aria-label="edit"
            color="secondary"
        >
            <AddIcon />
        </Button>
        )}    
        

        <UnitTransferModal
            open={open}
            onClose={handleClose}
            isEdit={isEdit}
            unitTransfer={editUnitTransfer}
            handleSave={handleSave}
        />

        <div className="p-4">
            <div className="d-flex flex-column gap-3 bg-white p-3 mb-4" style={{borderRadius: '5px'}}>
                <p className="fs-med-semibold text-primary p-0 m-0">Filter By:</p>
                <div className="d-flex gap-4 align-item-center justify-content-center flex-row flex-grow-1">
                    <Autocomplete
                        sx={{ flex: 1 }}
                        id="outlined-basic-1"
                        name="oppRid"
                        variant="outlined"
                        options={schemeList}
                        getOptionLabel={(option) => option.oppTitle}
                        value={schemeList.find((option) => option.oppRid === oppRid) || null}
                        fullWidth
                        onChange={(e, v) => setOppRid(v.oppRid)}
                        renderInput={(params) => <TextField {...params} label="Scheme" />}
                        renderOption={(props, option) => (
                            <li {...props} key={option.oppRid}>{option.oppTitle}</li>
                        )}
                        size="small"
                    />
                    <Autocomplete
                        sx={{ flex: 1 }}
                        id="outlined-basic-2"
                        name="investorOpportunity"
                        variant="outlined"
                        options={sellerList}
                        getOptionLabel={(option) => option.sellerFullName}
                        value={sellerList.find((option) => option.sellerInvRid === sellerInv?.sellerInvRid) || null}
                        fullWidth
                        onChange={(e, v) => setSellerInv(v)}
                        renderInput={(params) => <TextField {...params} label="Seller" />}
                        renderOption={(props, option) => (
                            <div className="d-flex align-items-center" {...props} key={option.sellerInvRid}>
                                <AccountCircleIcon color="primary" fontSize="large" />
                                <div className="flex-grow-1 ms-2">
                                    <p className="fs-med-medium p-0 m-0">{option.sellerFullName}</p>
                                </div>
                            </div>
                        )}
                        size="small"
                    />
                    <Autocomplete
                        sx={{ flex: 1 }}
                        id="outlined-basic-3"
                        name="investor"
                        variant="outlined"
                        options={buyerList}
                        getOptionLabel={(option) => option.buyerFullName}
                        value={buyerList.find((option) => option.buyerInvRid === buyerInv?.buyerInvRid) || null}
                        fullWidth
                        onChange={(e, v) => setBuyerInv(v)}
                        renderInput={(params) => <TextField {...params} label="Buyer" />}
                        renderOption={(props, option) => (
                            <div className="d-flex align-items-center" {...props} key={option.buyerInvRid}>
                                <AccountCircleIcon color="primary" fontSize="large" />
                                <div className="flex-grow-1 ms-2">
                                <p className="fs-med-medium p-0 m-0">{option.buyerFullName}</p>
                                </div>
                            </div>
                        )}
                        size="small"
                    />
                    <Tooltip title="Clear">
                        <IconButton onClick={()=>resetFilter(allUnitTransferList)}>
                            <CancelIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            <div className="bg-white p-3" style={{borderRadius: '5px'}}>
            {
                isLoading ? [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
                    <Skeleton
                    animation="wave"
                    key={index}
                    style={{ marginBottom: 6 }}
                    variant="rectangle"
                    height={40}
                    />
                )) : 
                <div>
                    {unitTransferList.length > 0 ? (
                    <table
                        className="table table-striped table-borderless w-100"
                        id="order-table"
                    >
                        <thead>
                            <tr className="fs-normal-semibold text-center">
                                {/* <th scope="col">
                                    <p className="m-0">Actions</p>
                                </th> */}
                                <th scope="col">
                                    <p className="m-0">Scheme</p>
                                </th>
                                <th scope="col">
                                    <p className="m-0">Seller</p>
                                </th>
                                <th scope="col">
                                    <p className="m-0">Buyer</p>
                                </th>
                                <th scope="col">
                                    <p className="m-0">Date</p>
                                </th>
                                <th scope="col">
                                    <p className="text-end m-0">Amount</p>
                                </th>
                                <th scope="col">
                                    <p className="text-end m-0">Market Value</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-table-blue">
                        {unitTransferList.map((item) => {
                            return (
                                <tr className="fs-normal-medium text-center"
                                    key={item.utRid}
                                >
                                    {/* <td className="p-0">
                                        <div className="d-flex">
                                            
                                        </div>
                                    </td> */}
                                    <td className="pt-3">{item.oppTitle}</td>
                                    <td className="pt-3">{item.sellerFullName}</td>
                                    <td className="pt-3">{item.buyerFullName}</td>
                                    <td className="pt-3">{formatDateTime(item.date, false)}</td>
                                    <td className="pt-3 text-end">{toLocalePrice(item.amount)}</td>
                                    <td className="pt-3 text-end">{toLocalePrice(item.marketValue)}</td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                ) : (
                    <p>No Data..</p>
                )}
                </div>
            }
            </div>
        </div>
    </Layout>
  );
}

export default UnitTransfer;