import React, {useState, useEffect} from 'react';
import DialogLayout from '../../components/layout/DialogLayout';
import { apiInvestorOnboarding, apiInvestorOpportunity, apiUnitAllotmentFile } from '../../utils/api/api';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../utils/constants/constants";
import { useSnackbar } from 'notistack';
import { CircularProgress, Stack, TextField } from '@mui/material';

const UnitAllotFile = ({ open, toggleDialog, scheme, handleSave }) => {
    const [investorList, setInvestorList] = useState([]);
    const [filteredInvList, setFilteredInvList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [actionLoader, setActionLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const downloadFile = async (response) => {
        const blob = new Blob([response.data], { type: response.headers.get('content-type') });
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.setAttribute('download' , response.headers.get('content-disposition').split('"')[1]);
        downloadLink.click();
        window.URL.revokeObjectURL(url);
    }

    const getInvestorOpportunity = (param = {}) => {
        setIsLoading(true);
        param.oppRid = scheme.oppRid;
        apiInvestorOpportunity(param)
            .then((data) => {
              setIsLoading(false);
                setInvestorList(data.investorOpportunities);
                setFilteredInvList(
                  data.investorOpportunities.filter(item => !item.consent || !item.fundStatus)
                );
            })
            .catch((error) => {
              setIsLoading(false);
                enqueueSnackbar(error,SNACKBAR_ERROR);
            });
    }

    useEffect(() => {
        if(open){
            getInvestorOpportunity();
        }
    }, [open]);

    const downloadUnitAllotFile = () => {
        setActionLoader(true);
        apiUnitAllotmentFile({
            "oppRid": scheme?.oppRid
        }).then((data) => {
            setActionLoader(false);
            downloadFile(data);
            handleSave();
        })
        .catch((error) => {
            setActionLoader(false);
            enqueueSnackbar(error,SNACKBAR_ERROR);
        });
    }

  return (
    <DialogLayout
      open={open}
      title="Unit Allotment File"
      maxWidth={filteredInvList.length === 0 && 'sm'}
      actionLoader={actionLoader}
      closeAction={toggleDialog}
      negativeAction={
        {label: 'Cancel', onClick : toggleDialog}
      }
      positiveAction={{
        label: filteredInvList.length === 0 ? 'Download' : 'Download Anyway',
        onClick : downloadUnitAllotFile}
    }>
        {isLoading ? <CircularProgress /> :
        (filteredInvList.length > 0 ?
            <Stack direction="column" spacing={2}>
            <p className="fs-large-regular w-75">
              Please complete the pending activities before proceeding further
            </p>

            <table className="table table-striped table-borderless w-100">
              <thead>
                <tr className="fs-normal-semibold text-center">
                  <th scope="col">
                    <p className="mb-2">Investor</p>
                  </th>
                  <th scope="col">
                    <p className="mb-2">Consent</p>
                  </th>
                  <th scope="col">
                    <p className="mb-2">Fund</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                { filteredInvList.map((data, key) => {
                  return(
                    <tr className="fs-normal-medium text-center" key={key}>
                      <td className="pt-3">
                        {data.invFullName}
                        <span className="fs-small-regular text-muted ms-2">{data.invCode}</span>
                      </td>
                      <td className="pt-3">
                        {data.consent ? 'Received' : <span className="text-danger">Pending</span>}
                      </td>
                      <td className="pt-3">
                        {data.fundStatus ? 'Received' : <span className="text-danger">Pending</span>}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Stack> : <Stack direction="column" spacing={2}>
            <p className="fs-large-regular w-75">
              click on download to proceeding further
            </p>
        </Stack>) }
    </DialogLayout>
  );
}

export default UnitAllotFile;