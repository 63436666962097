import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { IconButton, Skeleton } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { getCompanies } from "../../utils/api/api";
import CompanyModal from "./CompanyModal";
import CompanyDeleteModal from "./CompanyDeleteModal";
import {
  RECORD_DISPLAY_LIMIT,
  SNACKBAR_ERROR,
} from "../../utils/constants/constants";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { isSubAdmin } from "../../utils/StringUtil";

const Company = () => {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [editCompany, setEditCompany] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const userType = process.env.REACT_APP_USER_TYPE;

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    setOpenDelete(false);
    setEditCompany({});
  };

  const handleAdd = () => {
    setOpen(true);
    setIsEdit(false);
    setEditCompany(null);
  };

  const handleEdit = (id) => {
    setEditCompany(companyList.find((item) => item.compRid === id));
    setOpen(true);
    setIsEdit(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleDelete = (id) => {
    setEditCompany(companyList.find((item) => item.compRid === id));
    setOpenDelete(true);
    setIsEdit(true);
  };

  const handleSave = () => {
    setPage(1);
    getCompanyList();
    handleClose();
  };

  useEffect(() => {
    getCompanyList({ pageNumber: page, name: searchTerm });
  }, [page]);

  useEffect(() => {
    if (searchTerm.length < 3) {
      if (searchTerm.trim() === "") {
        getCompanyList();
      } else {
        return;
      }
    } else {
      getCompanyList({ name: searchTerm.trim() });
    }

    setPage(1);
  }, [searchTerm]);

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const getCompanyList = (param = {}) => {
    param.pageNumber = page;
    param.recLimit = RECORD_DISPLAY_LIMIT;
    setIsLoading(true);
    getCompanies(param)
      .then((data) => {
        setCompanyList(data.companyList);
        if (page === 1) {
          setTotalRecords(data.records);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(e, SNACKBAR_ERROR);
      });
  };

  useEffect(() => {
    getCompanyList();
  }, []);

  return (
    <Layout title="Company">
      {userType === "ADMIN" && !isSubAdmin() && (
        <Button
          sx={{
            height: "64px",
            borderRadius: "50%",
            position: "fixed",
            bottom: "20px",
            right: "20px",
          }}
          onClick={handleAdd}
          variant="contained"
          aria-label="edit"
          color="secondary"
        >
          <AddIcon />
        </Button>
      )}

      <CompanyModal
        open={open}
        onClose={handleClose}
        company={editCompany}
        isEdit={isEdit}
        handleSave={() => handleSave()}
      />
      <CompanyDeleteModal
        open={openDelete}
        onClose={handleClose}
        company={editCompany}
        handleSave={(company) => handleSave(company)}
      />

      <div className="p-4">
        <div
          className="d-flex flex-row justify-content-between bg-white p-3 mb-4"
          style={{ borderRadius: "5px" }}
        >
          <div
            className="search-input-btn d-flex flex-row justify-content-between rounded bg-white px-3"
            style={{ border: "1px solid #000" }}
          >
            <div className="my-auto">
              <SearchIcon sx={{ cursor: "pointer" }} />
            </div>
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => handleSearch(e)}
              className="form-control custom-form-control border-0 bg-transparent fs-large-regular "
              placeholder="Search by Name"
            />
            <div onClick={() => handleClearSearch()} className="my-auto">
              <CloseIcon sx={{ cursor: "pointer" }} />
            </div>
          </div>
          <div className="justify-content-evenly d-flex align-items-center">
            <Pagination
              color="secondary"
              size="small"
              siblingCount={0}
              onChange={handlePageChange}
              count={
                totalRecords < RECORD_DISPLAY_LIMIT
                  ? 1
                  : Math.ceil(totalRecords / RECORD_DISPLAY_LIMIT)
              }
              page={page}
            />
          </div>
        </div>

        <div className="bg-white py-2 px-3" style={{ borderRadius: "5px" }}>
          {isLoading ? (
            [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
              <Skeleton
                animation="wave"
                key={index}
                style={{ marginBottom: 6 }}
                variant="rectangle"
                height={40}
              />
            ))
          ) : (
            <div>
              {companyList.length > 0 ? (
                <table
                  className="table table-striped table-borderless w-100"
                  id="order-table"
                >
                  <thead>
                    <tr className="fs-normal-semibold text-center">
                      { userType === 'ADMIN' && !isSubAdmin() && (
                        <th scope="col">
                          <p className="mb-2">Actions</p>
                        </th>
                      )}
                      <th scope="col">
                        <p className="mb-2">Company Title</p>
                      </th>
                      <th scope="col">
                        <p className="mb-2">Legal Name of Entity</p>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-table-blue">
                    {companyList.map((item) => {
                      return (
                        <tr
                          className="fs-normal-medium text-center"
                          key={item.compRid}
                        >
                          {userType === "ADMIN" && !isSubAdmin() && (
                          <td className="p-0">                         
                              <div className="d-flex">
                                <IconButton
                                  onClick={() => handleEdit(item.compRid)}
                                  aria-label="edit"
                                >
                                  <EditIcon className="text-blue" />
                                </IconButton>
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => {
                                    handleDelete(item.compRid);
                                  }}
                                >
                                  <DeleteOutlineIcon className="text-danger" />
                                </IconButton>
                              </div>                           
                          </td>
                          )}
                          <td className="pt-3">{item.title}</td>
                          <td className="pt-3">{item.legalNameOfEntity}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p>No Data..</p>
              )}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Company;
