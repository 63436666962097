const LSK_ADMIN_AUTH_TOKEN = 'admin_auth_token'
const ADMIN_REFRESH_TOKEN = 'admin_refresh_token';
const ADMIN_TRANSACTION_TOKEN = 'admin_transaction_token';

const SUB_ADMIN_TYPE = 'sub_admin_type';

export const getAdminAuthtoken = () => {
    return localStorage.getItem(LSK_ADMIN_AUTH_TOKEN)
}

export const setAdminAuthToken = (token) => {
    localStorage.setItem(LSK_ADMIN_AUTH_TOKEN, token)
}

export const removeAdminAuthToken = () => {
    localStorage.removeItem(LSK_ADMIN_AUTH_TOKEN)
}

export const getAdminRefreshToken = () => {
    return localStorage.getItem(ADMIN_REFRESH_TOKEN);
}
export const setAdminRefreshToken = (token) => {
    localStorage.setItem(ADMIN_REFRESH_TOKEN, token);
}
export const removeAdminRefreshToken = () => {
    localStorage.removeItem(ADMIN_REFRESH_TOKEN);
}

export const getAdminTransactionToken = () => {
    return localStorage.getItem(ADMIN_TRANSACTION_TOKEN);
}
export const setAdminTransactionToken = (token) => {
    localStorage.setItem(ADMIN_TRANSACTION_TOKEN, token);
}
export const removeAdminTransactionToken = () => {
    localStorage.removeItem(ADMIN_TRANSACTION_TOKEN);
}

export const getSubAdminType = () => {
    return localStorage.getItem(SUB_ADMIN_TYPE);
}
export const setSubAdminType = (token) => {
    localStorage.setItem(SUB_ADMIN_TYPE, token);
}
export const removeSubAdminType = () => {
    localStorage.removeItem(SUB_ADMIN_TYPE);
}