import React, {useState,useEffect} from 'react';
import DrawerLayout, { ScoreBoard } from '../../components/layout/DrawerLayout';
import { Autocomplete, Stack, Switch, TextField } from '@mui/material';
import { apiInvestorOpportunity, apiSaveFunds, apiWallet, getInvestors, getTransactions } from '../../utils/api/api';
import { formatDateTime, toLocalePrice } from '../../utils/StringUtil';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import { DatePicker } from "@mui/x-date-pickers";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";
import { useSnackbar } from 'notistack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const FundReceivedDrawer = ({ open, onClose, isEdit, investor, handleSave }) => {
  const [investorList, setInvestorList] = useState([]);
  const [invRid, setInvRid] = useState(null);
  const [investorFullName, setInvestorFullName] = useState('');
  const [invCode, setInvCode] = useState(null);
  const [walletAmount, setWalletAmount] = useState(0.00);
  const [balance, setBalance] = useState(0.00);
  const [amountReceived, setAmountReceived] = useState(0.00);
  const [receivedDate, setReceivedDate] = useState(
    new Date().toISOString().split('T')[0]
  );
  const [wiredSchemeList, setWiredSchemeList] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
  const [remark, setRemark] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [actionLoader, setActionLoader] = useState(false);
  const userType = process.env.REACT_APP_USER_TYPE;

  const handleReceivedDate = (selectedDate) => {
    const date = new Date(selectedDate?.$d);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    // const formattedDate = date.toISOString().split('T')[0];
    setReceivedDate(formattedDate);
  }

  const handleInvOppSwitch = (ioRid) => {
    const updatedList = wiredSchemeList.map(item =>
        item.ioRid === ioRid ? { ...item, fundStatus: item.fundStatus ? 0 : 1 } : item
    );
    setWiredSchemeList(updatedList);
  }

  const getInvestorList = (param = {}) => {
    param.mcaStatus = 1;
    getInvestors(param)
      .then((data) => {
        setInvestorList(data.investorList);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getWallet = (param = {}) => {
    param.invRid = invRid;
    apiWallet(param)
      .then((data) => {
        setWalletAmount(data.balanceAmt);
        setBalance(data.balanceAmt);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getWiredScheme = (param = {}) => {
    param.invRid = invRid;
    param.drawdown = null;
    param.consent = null;
    param.fundStatus = 0;
    apiInvestorOpportunity(param)
        .then((data) => {
            setWiredSchemeList(data.investorOpportunities);
        })
        .catch((error) => {
            enqueueSnackbar(error,SNACKBAR_ERROR);
        });
  }

  const getTransactionList = (param = {}) => {
    param.invRid = invRid;
    getTransactions(param)
        .then((data) => {
            setTransactionList(data.transactions);
        })
        .catch((error) => {
            enqueueSnackbar(error,SNACKBAR_ERROR);
        });
  }

  useEffect(() => {
    const schemeTotal = wiredSchemeList.filter(item => item.fundStatus)
      .reduce((total, item) => total + item.totalAmount, 0);
    const totalBalance = (parseFloat(walletAmount) + parseFloat(amountReceived)) - parseFloat(schemeTotal);
    setBalance(totalBalance);
  }, [amountReceived, wiredSchemeList]);

  useEffect(() => {
    const investorDetails = investorList.find((option) => option.invRid === invRid) || null;
    if(investorDetails){
      setInvCode(investorDetails.invCode);
      getWallet();
      getWiredScheme();
      getTransactionList();
    }
  }, [invRid]);

  useEffect(() => {
    getInvestorList();
  }, []);

  useEffect(() => {
    setInvRid(isEdit ? (investor.invRid || null) : null);
    setWalletAmount(isEdit ? walletAmount : 0.00);
    setInvCode(isEdit ? investor.invCode : '');
    setInvestorFullName(isEdit ? investor.fullName : '');
    setAmountReceived(0.00);
    setRemark('');
    setWiredSchemeList([]);
    setTransactionList([]);
  }, [open]);

  const saveFund = () => {
    const reqBody = {
      "invRid": invRid,
      "amt": amountReceived,
      "tranDatetime": receivedDate,
      "remark": remark,
      "investorOpportunityList": wiredSchemeList.filter(item => item.fundStatus),
    }
    setActionLoader(true);
    apiSaveFunds(reqBody)
      .then((data) => {
        setActionLoader(false);
          enqueueSnackbar(data,SNACKBAR_SUCCESS);
          handleSave();
      })
      .catch((error) => {
        setActionLoader(false);
        enqueueSnackbar(error,SNACKBAR_ERROR);
      });
  }

  const onSubmit = () => {
    if(!invRid){
      enqueueSnackbar("Please select investor", SNACKBAR_WARNING);
    } else if(parseFloat(amountReceived) <= 0){
      enqueueSnackbar("Invalid amount", SNACKBAR_WARNING);
    } else if(parseFloat(balance) < 0){
      enqueueSnackbar("Wallet Amount Exceeded", SNACKBAR_WARNING);
    } else {
      saveFund();
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
    <DrawerLayout
      open={open}
      title="Fund Received"
      actionLoader={actionLoader}
      closeAction={onClose}
      negativeAction={
        {label: 'Cancel', onClick : onClose}
      }
      positiveAction={{
        label: "Save",
        onClick: onSubmit  
      }}
    >
      <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
        {
          isEdit ?
          <ScoreBoard
            label="Investor"
            value={investorFullName}
            flex={1}
          /> : 
          <Autocomplete
            sx={{ flex: 1 }}
            id="outlined-basic"
            name="invRid"
            variant="outlined"
            options={investorList}
            getOptionLabel={(option) => option.fullName}
            value={investorList.find((option) => option.invRid === invRid) || null}
            fullWidth
            onChange={(e, v) => setInvRid(v.invRid)}
            disabled={isEdit}
            renderInput={(params) => <TextField {...params} label="Select Investor" />}
            renderOption={(props, option) => (
              <div className="d-flex align-items-center" {...props} key={option.invRid}>
                <AccountCircleIcon color="primary" fontSize="large" />
                <div className="flex-grow-1 ms-2">
                  <p className="fs-med-medium p-0 m-0">{option.fullName}</p>
                  <p className="fs-small-regular text-muted p-0 m-0">{option.invCode}</p>
                </div>
              </div>
              // <li {...props} key={option.invRid}>{option.fullName}</li>
            )}
          />
        }
        <Stack sx={{ flex: 1 }} direction="row" spacing={2}>
          <ScoreBoard
            label="Investor Code"
            value={invCode}
            flex={1}
          />
          <ScoreBoard
            label="Wallet Amount"
            value={toLocalePrice(walletAmount)}
            flex={1}
          />
          {/* <ScoreBoard
            label="Balance"
            value={toLocalePrice(balance)}
            flex={1}
          /> */}
        </Stack>
      </Stack>
      { userType === 'ADMIN' && <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
          <Stack sx={{ flex: 1 }} direction="column" spacing={2}>
     
          <TextField
            sx={{ flex: 1 }}
            id="outlined-basic"
            name="amountReceived"
            type="number"
            label="Amount Received"
            variant="outlined"
            value={amountReceived}
            onChange={(e) => setAmountReceived(e.target.value)}
            fullWidth
          />
          <DatePicker
            sx={{ flex: 1 }}
            id="outlined-basic"
            name="receivedDate"
            label="Received Date"
            variant="outlined"
            value={receivedDate}
            fullWidth
            onChange={(v) => handleReceivedDate(v)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
        <TextField
          sx={{ flex: 1 }}
          id="outlined-multiline-static"
          label="Remark"
          multiline
          rows={4}
          value={remark}
          onChange={(e)=>setRemark(e.target.value)}
          fullWidth
        />
      </Stack> }
      
      
      <Stack sx={{ marginBottom: "2rem" }} direction="column" className={wiredSchemeList.length === 0 ? 'd-none' : ''}>
        <p className="section-label mb-1">Wired schemes</p>
        <table className="table table-striped table-borderless w-100">
          <thead>
            <tr className="fs-normal-semibold text-center">
              <th scope="col">
                <p className="mb-2">Scheme</p>
              </th>
              <th scope="col">
                <p className="mb-2">Comiitted</p>
              </th>
              <th scope="col">
                <p className="mb-2">Upfront fee</p>
              </th>
              <th scope="col">
                <p className="mb-2">Total</p>
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {wiredSchemeList.map((data, key)=>{
              return(
                <tr className="fs-normal-medium text-center" key={key}>
                  <td className="pt-3">{data.oppTitle}</td>
                  <td className="pt-3">{toLocalePrice(data.commitment)}</td>
                  <td className="pt-3">{toLocalePrice(data.upfrontAmount)}</td>
                  <td className="pt-3">{toLocalePrice(data.totalAmount)}</td>
                  <td className="pt-0">
                    {
                      userType === 'ADMIN' ?
                      data.fundTransferred ?
                      <div className="fs-small-semibold pt-3">Fund Transferred</div> :
                      <Switch color="secondary" checked={data.fundStatus} onChange={()=>handleInvOppSwitch(data.ioRid)} disabled={data.fundTransferred} />
                      : 
                      data.fundTransferred ? <div className="fs-small-semibold pt-3">Fund Transferred</div>
                       :
                      <div className="fs-small-semibold pt-3">Fund Not Transferred</div>
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Stack>
      <Stack sx={{ marginBottom: "2rem" }} direction="column" className={transactionList.length === 0 ? 'd-none' : ''}>
        <p className="section-label mb-1">Payment logs</p>
        <table className="table table-striped table-borderless w-100">
          <thead>
            <tr className="fs-normal-semibold text-center">
              <th scope="col">
                <p className="mb-2">Date</p>
              </th>
              <th scope="col">
                <p className="mb-2">Amount</p>
              </th>
              <th scope="col">
                <p className="mb-2">Remarks</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {transactionList.map((data, key)=>{
                return(
                    <tr className="fs-normal-medium text-center" key={key}>
                        <td className="pt-3">{formatDateTime(data.tranDatetime, false)}</td>
                        <td className="pt-3">{toLocalePrice(data.amt)}</td>
                        <td className="pt-3">{data.remark}</td>
                    </tr>
                );
            })}
          </tbody>
        </table>
      </Stack>
    </DrawerLayout>
    </LocalizationProvider>
  );
}

export default FundReceivedDrawer;