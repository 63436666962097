import { getSubAdminType } from "./LocalStorageUtils";
import { CKYC_FILTER, DEMAT_STATUS_FILTER, INVESTOR_STATUS_FILTER, MCA_FILTER, STAMP_FILTER, USER_TYPE_SUB_ADMIN } from "./constants/constants";

export const toLocalePrice = (value) => {
    const result = Number(+value).toLocaleString('en-IN', { currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return isNaN(parseFloat(result)) ? 0 : result;
}

export const toLocalePriceNoDecimal = (value) => {
    const result = Number(+value).toLocaleString('en-IN', { currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 });
    return isNaN(parseFloat(result)) ? 0 : result;
}

export const toCompactPricePrefix = (value) => {
    return `₹ ${toCompactPrice(value)}`
}

export const toCompactPrice = (value) => {
    const result = Number(+value)
        .toLocaleString(
            'en-IN', {
            //@ts-ignore
            notation: "compact",
            compactDisplay: "short",
            maximumFractionDigits: 2,
        }).replace('T', 'K');
        
    return isNaN(parseFloat(result)) ? 0 : result;
}

export const handleMuiDatePicker = (selectedDate) => {
    const date = new Date(selectedDate?.$d);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}

export const formatDateTime = (dateTimeString, flagShowTime) => {
    const date = new Date(dateTimeString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedYear = year;

    let formattedTime = '';
    if (flagShowTime) {
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        formattedTime = ` ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
  
    return `${formattedDay}/${formattedMonth}/${formattedYear}${formattedTime}`;
}

export const isSubAdmin = () => {
    return getSubAdminType() == USER_TYPE_SUB_ADMIN;
}

export const getInvestorFilterCount = (
    ckyc, mca, stamp, investorStatus, dematCheckBox, dematStatus
) => {
    let count = 0;

    if(ckyc !== CKYC_FILTER.ALL){
        count++;
    }
    if(mca !== MCA_FILTER.ALL){
        count++;
    }
    if(stamp !== STAMP_FILTER.ALL){
        count++;
    }
    if(investorStatus !== INVESTOR_STATUS_FILTER.ALL && investorStatus !== undefined){
        count++;
    }
    if(dematCheckBox){
        count++;
    }
    if(dematStatus !== DEMAT_STATUS_FILTER.ALL && dematStatus !== undefined){
        count++;
    }

    return count;
}

export const isValidNsdldpId = (nsdlDpId) => {
    return /^IN[0-9]{6}$/.test(nsdlDpId);
}

export const isValidCdsldpId = (cdslDpId) => {
    return /^[0-9]{8}$/.test(cdslDpId);
}

export const isValidClientId = (clientId) => {
    return /^[0-9]{8}$/.test(clientId);
}
