import axios from "axios"
import { BASE_URL } from "../constants/constants"
import { getAdminAuthtoken } from "../LocalStorageUtils"



export const httpPost = ({ path, body = null }) => {

    return new Promise((resolve, reject) => {
        axios.post(
            `${BASE_URL}${path}`,
            body
        )
            .then((res) => {
                return resolve(res)
            })
            .catch((e) => {
                return reject(e)
            })
    })

}

export const httpGet = ({ path, param = null }) => {

    return new Promise((resolve, reject) => {
        axios.get(
            `${BASE_URL}${path}`,
            {
                params: param
            }
        )
            .then((res) => {
                return resolve(res)
            })
            .catch((e) => {
                return reject(e)
            })
    })
}

export const httpPut = ({ path, body = null }) => {

    return new Promise((resolve, reject) => {
        axios.put(
            `${BASE_URL}${path}`,
            body
        )
            .then((res) => {
                return resolve(res)
            })
            .catch((e) => {
                return reject(e)
            })
    })

}

export const httpDelete = ({ path, param = null }) => {

    return new Promise((resolve, reject) => {
        axios.delete(
            `${BASE_URL}${path}`,
            {
                params: param
            }
        )
            .then((res) => {
                return resolve(res)
            })
            .catch((e) => {
                return reject(e)
            })
    })
}

export const httpFileUpload = ({ path, file = null }) => {

    return new Promise((resolve, reject) => {
        axios.post(
            `${BASE_URL}${path}`,
            file
        )
        .then((res) => {
             return resolve(res)
        })
        .catch((e) => {
            return reject(e)
        })

    })
}

export const httpMultiFileUpload = ({ path, files = null }) => {

    return new Promise((resolve, reject) => {
        axios.post(
            `${BASE_URL}${path}`,
            files,
            {
                headers: {
                    // 'Content-Type': 'multipart/form-data' // Do not set this header
                }
            }
        )
        .then((res) => {
             return resolve(res)
        })
        .catch((e) => {
            return reject(e)
        })

    })
}

export const getFile = ({ path, param = null }) => {
    // const instance = axios.create({
    //     baseURL: BASE_URL,
    // });

    return new Promise((resolve, reject) => {
        axios.get(`${BASE_URL}${path}`,
            {
                observe: 'response',
                responseType: 'blob',
                // headers: {

                //     'Content-Type': 'application/json',
                //     'Authorization': `Bearer ${token}`

                // },
                params: param,
            }
        )
            .then((res) => {
                return resolve(res)
            })
            .catch(e => {
                reject(e)
            }) 
    })
}
