import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getAdminAuthtoken } from "../utils/LocalStorageUtils";

const PrivateRoute = (props) => {
  const userType = process.env.REACT_APP_USER_TYPE
  const auth = getAdminAuthtoken() 
  return (
    <Route
      path={props.path}
      render={({ location }) =>
        auth ? (
          <props.component />
        ) : (
          <Redirect to={{ pathname: userType === 'ADMIN' ? "/admin/login" : "/partner/login" , state: { from: location } }} />
        )
      }
    />
  );
};
export default PrivateRoute;
