import axios from "axios"
import { getAdminAuthtoken, getAdminRefreshToken, removeAdminAuthToken, setAdminAuthToken, setAdminRefreshToken, setAdminTransactionToken } from "../LocalStorageUtils"
import { BASE_URL } from "../constants/constants"


const OPEN_URL_LIST = [
    '/auth',
    '/verify-otp',
    '/refresh-token',
    '/partner/auth'
]

const MULTI_PART_LIST = [
    '/upload-image',
    '/upload-document',
    '/admin/unit-allotment',
    '/upload-multiple-document'
]

const IGNORE_HEADERS = [
    '/upload-multiple-document'
]

const RESPONSE_BLOB_LIST = [
    '/admin/unit-allotment-file',
    '/admin/investor-on-boarding-file',
    '/admin/fee-generate-file',
    '/admin/opportunity/summary/download',
    '/admin/opportunity/partner-payments/download',
    '/admin/investor-list/download',
    '/admin/demat-list-download',
]

const AUTHENTICATE = '/auth'
const PARTNERAUTHENTICATE = '/partner/auth'
const OTP_AUTH = '/verify-otp'
const REFRESH_TOKEN = '/refresh-token'
const RESEND_OTP_API = '/resend-otp'

let isRefreshing = false; // Track whether a token refresh is in progress
let refreshSubscribers = []; // Array to hold the pending API requests

// const destroyToken = () => {
//     removeAdminAuthToken()
// }

const handleServerError = (error) => {

    if( error && error.response && error.response.data && error.response.data.description){
        return error.response.data.description
    }

    if(error.message){
        return error.message
    }

    return "Something went wrong"
}

const createRequestInterceptor = () => {

    axios.interceptors.request.use(config => {

        let token = getAdminAuthtoken()

        // open url list will be excluded
        if (!OPEN_URL_LIST.includes(config.url.replace(BASE_URL, '')) && token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        if(MULTI_PART_LIST.includes(config.url.replace(BASE_URL, ''))){

            
            config.headers['Content-Type'] = 'multipart/form-data'


        } else {
           

            if(!IGNORE_HEADERS.includes(config.url.replace(BASE_URL, ''))){
                config.headers['Content-Type'] = 'application/json';
            }


        }
        

        // decamelize payload
        // if (config.method.toUpperCase() === 'POST' && config.data) {
        //     config.data = decamelizeKeys(config.data, { split: /(?=[A-Z0-9])/ })
        // }
        return config
    }, error => {
        return Promise.reject(error)
    })
}

const createResponseInterceptor = () => {


    const interceptor = axios.interceptors.response.use(({ config, data,headers }) => {


    if ([AUTHENTICATE, PARTNERAUTHENTICATE, OTP_AUTH, REFRESH_TOKEN, RESEND_OTP_API].includes(config.url.replace(BASE_URL, ''))) {

        /*
           -> save token and user data in local storage
           -> delete token from data object
        */

        //TODO : Change below code according to API response
        if (data.status && data.data.jwt && data.data.refreshToken ) {
            setAdminAuthToken(data.data.jwt)
            setAdminRefreshToken(data.data.refreshToken)

            if(data.data.transactionToken){
                setAdminTransactionToken(data.data.transactionToken)
                delete data.data.transactionToken
            }
            
            delete data.data.jwt
            delete data.data.refreshToken

        }

        if (data.status && data.data.transactionToken ) {
            setAdminTransactionToken(data.data.transactionToken)
            delete data.data.transactionToken
        }

    }

    if(RESPONSE_BLOB_LIST.includes(config.url.replace(BASE_URL, ''))){
        return Promise.resolve({headers, data })
    }

    return Promise.resolve(data)

}, async e =>  {

    // reject error if not 401
    // if (e.response.status !== 401) {
    //     return Promise.reject(handleServerError(e))
    // }

    // if (e?.response?.status === 401) { //TODO : exclude authenticate api
    //     destroyToken()
    //     window.location = "/admin/login";
    // }

    const originalRequest = e.config;
    const reqUrl = e.response.config.url.replace(BASE_URL, '');

    if (e?.response?.status === 401 && !originalRequest._retry && reqUrl !== REFRESH_TOKEN) {
        originalRequest._retry = true;

        const retryOriginalRequest = new Promise((resolve) => {
            refreshSubscribers.push(() => resolve(axios(originalRequest)))
        });

        if (!isRefreshing) {
            isRefreshing = true

            try {

                const body = {
                    refreshToken: getAdminRefreshToken()
                }

                removeAdminAuthToken()

                 await axios
                    .post(
                        `${BASE_URL}${REFRESH_TOKEN}`,
                        body,
                    )

                refreshSubscribers.forEach((subscriber) => subscriber())
                refreshSubscribers = []

            } catch (refreshError) {
                localStorage.clear();
                window.location = "/admin/login";
            }

            isRefreshing = false
        }

        return retryOriginalRequest
    }


    //TODO: uncomment and update 
    return Promise.reject(handleServerError(e))

    /*
        -> Eject response interceptor to avoid cyclic infinite 401
        -> Send token refresh request
        -> After sucessfull response store token and send previous request
        -> In case of error clear token and user data and navigate to login 
    */

    // axios.interceptors.response.eject(interceptor);

    // return axios.post(
    //     `${BASE_URL}silent-login`,
    //     null
    // )
    //     .then((res) => {
    //         //TODO : Change below code according to API response
    //         setAuthToken(res.data.data)
    //         createResponseInterceptor()
    //         return axios(e.response.config)
    //     })
    //     .catch((voError) => {
    //         destroyToken()
    //         createResponseInterceptor()
    //         return Promise.reject(handleServerError(voError))
    //     })
})
}

createRequestInterceptor()
createResponseInterceptor()
