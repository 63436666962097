import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { Divider } from "@mui/material";
import {
  getCoInvestors,
  getCompanies,
  getDataDictionaryValues,
  getMasterClass,
  getOppCoInvestors,
  getPartners,
} from "../../utils/api/api";
import {
  DD_TYPE_PROJECT_DOMAIN,
  DD_TYPE_STAGE_OF_COMPANY,
  DD_TYPE_INVESTMENT_TYPE,
  DD_TYPE_INV_ROUND_TYPE,
  DD_TYPE_OPPORTUNITY_STATUS,
  SNACKBAR_ERROR,
} from "../../utils/constants/constants";
import {
  isTextEmpty,
} from "../../utils/Validator";
import { useSnackbar } from "notistack";
import DrawerLayout from "../../components/layout/DrawerLayout";
import { toLocalePrice } from "../../utils/StringUtil";

const SchemeInfo = ({ open, onClose, isEdit, project, handleSave }) => {
const [projectForm, setProjectForm] = useState(null);
const [companyList, setCompanyList] = useState([]);
const [stageOfCompanyList, setStageOfCompanyList] = useState([]);
const [typeOfInvList, setTypeOfInvList] = useState([]);
const [domainList, setDomainList] = useState([]);
const [invRoundList, setInvRoundList] = useState([]);
const [coInvestorsList, setCoInvestorsList] = useState([]);
const [oppStatusList, setOppStatusList] = useState([]);
const [carry, setCarry] = useState(0.0);
const [hurdle, setHurdle] = useState(0.0);
const [closureDate, setClosureDate] = useState(
  new Date().toISOString().split("T")[0]
);
const { enqueueSnackbar } = useSnackbar();
const [masterClassList, setMasterClassList] = useState([]);
const [partnerRid, setPartnerRid] = useState(null);
const [partnerList, setPartnerList] = useState([]);
const userType = process.env.REACT_APP_USER_TYPE

const getPartnersList = () => {
  setPartnerList([]);
  getPartners()
  .then((res) => {
      setPartnerList(res.partnerMasterList);
  })
  .catch((e) => {
      enqueueSnackbar(e, SNACKBAR_ERROR);
  });
}

const getCompanyList = () => {
  getCompanies()
    .then((data) => {
      setCompanyList(data.companyList);
    })
    .catch((error) => {
      showError(error);
    });
};

const getDataDictionary = () => {
  getDataDictionaryValues()
    .then((data) => {
      const invTypes = data.filter(
        (item) => item.ddType === DD_TYPE_INVESTMENT_TYPE
      );
      const oppStatus = data.filter(
        (item) => item.ddType === DD_TYPE_OPPORTUNITY_STATUS
      );
      const domains = data.filter(
        (item) => item.ddType === DD_TYPE_PROJECT_DOMAIN
      );
      const stages = data.filter(
        (item) => item.ddType === DD_TYPE_STAGE_OF_COMPANY
      );
      const rounds = data.filter(
        (item) => item.ddType === DD_TYPE_INV_ROUND_TYPE
      );
      setTypeOfInvList(invTypes);
      setDomainList(domains);
      setStageOfCompanyList(stages);
      setInvRoundList(rounds);
      setOppStatusList(oppStatus);
    })
    .catch((e) => {
      showError(e);
    });
};

const getCoInvestorsList = () => {
  getCoInvestors()
    .then((data) => {
      setCoInvestorsList(data);
    })
    .catch((e) => {
      showError(e);
    });
};

const getOppInv = (params) => {
  getOppCoInvestors(params)
    .then((data) => {
      setProjectForm({
        ...project,
        coInvestorsList: data,
      });
    })
    .catch((e) => {
      showError(e);
    });
};

const showError = (message) => {
  enqueueSnackbar(message, SNACKBAR_ERROR);
};

const getMasterClassList = (param = {}) => {
  param.status = 1;
  getMasterClass(param)
    .then((data) => {
      setMasterClassList(data.classMasters);
    })
    .catch((error) => {
      console.log(error);
    });
};

useEffect(() => {
  getPartnersList();
  setCarry(0.0);
  setHurdle(0.0);
  getCompanyList();
  getDataDictionary();
  getCoInvestorsList();
  getMasterClassList();
  if (isEdit) {
    setClosureDate(project.fundingEndDate ?? closureDate);
    if (typeof project.oppFiles === "string") {
      project.oppFiles = JSON.parse(project.oppFiles);
    }
    if (typeof project.oppFiles === "string") {
      project.oppFiles = JSON.parse(project.oppFiles);
    }
    if (typeof project.thesis === "string") {
      project.thesis = JSON.parse(project.thesis);
    }
    if (typeof project.thesis === "string") {
      project.thesis = JSON.parse(project.thesis);
    }

    const masterClass =
      masterClassList.find((option) => option.cmRid === project.cmRid) ||
      null;
    if (masterClass) {
      setCarry(masterClass.carry);
      setHurdle(masterClass.hurdleRate);
    }

    setProjectForm(project);
    if (!isTextEmpty(project.coInvestors)) {
      getOppInv(project.coInvestors);
    }
  } else {
    setProjectForm({});
  }
  setPartnerRid(project?.partnerRid);
}, [project]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
     <DrawerLayout
        drawerWidth={"50%"}
        open={open}
        title={project ? project.oppTitle : ""}
        closeAction={onClose}
        positiveAction={{ label: "Close", onClick: onClose }}
      >
        <Stack sx={{ marginBottom: "1rem" }} direction="column" spacing={2}>
          <p className="section-label p-0 m-0">SCHEME DETAILS</p>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Scheme Title
              </p>
              <p className="fs-med-medium p-0 m-0">
                {project?.oppTitle !== null && project?.oppTitle !== ""
                  ? project?.oppTitle
                  : "---"}
              </p>
            </Box>
            {userType === 'ADMIN' && 
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Partner Name
              </p>
              <p className="fs-med-medium p-0 m-0">
                {project?.partnerName !== null && project?.partnerName !== ""
                  ? project?.partnerName
                  : "---"}
              </p>
            </Box>
            }
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Scheme Status
              </p>
              <p className="fs-med-medium p-0 m-0">
              {project?.oppStatus !== null && project?.oppStatus !== ""
                  ? project?.oppStatus
                  : "---"}
              </p>
            </Box>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Round Size
              </p>
              <p className="fs-med-medium p-0 m-0">
                {project?.roundSize !== null && project?.roundSize !== ""
                  ? toLocalePrice(project?.roundSize)
                  : "---"}
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
              <Box sx={{ flex: 1 }}>
                <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                  Minimum Amount
                </p>
                <p className="fs-med-medium p-0 m-0">
                  {project?.minAmount !== null && project?.minAmount !== ""
                    ? toLocalePrice(project?.minAmount)
                    : "---"}
                </p>
              </Box>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Domain
              </p>
              <p className="fs-med-medium p-0 m-0">
                {project?.domainIndex  !== null && project?.domainIndex !== ""
                  ? domainList.find(item => item.ddIndex === project?.domainIndex )?.ddValue
                  : "---"}
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
          <Box sx={{ flex: 1 }}>
                <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                  Valuation
                </p>
                <p className="fs-med-medium p-0 m-0">
                  {project?.valuation !== null &&
                  project?.valuation !== ""
                    ? toLocalePrice(project?.valuation)
                    : "---"}
                </p>
              </Box>
              <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Existing Commitment
              </p>
              <p className="fs-med-medium p-0 m-0">
                {project?.existCommit !== null && project?.existCommit !== ""
                  ? toLocalePrice(project?.existCommit)
                  : "---"}
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Type Of Investment
              </p>
              <p className="fs-med-medium p-0 m-0">
              {project?.typeOfInvIndex  !== null && project?.typeOfInvIndex !== ""
                  ? typeOfInvList.find(item => item.ddIndex === project?.typeOfInvIndex )?.ddValue
                  : "---"}
              </p>
            </Box>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Stage Of Company
              </p>
              <p className="fs-med-medium p-0 m-0">
                {project?.stageOfCompanyIndex !== null && project?.stageOfCompanyIndex !== ""
                  ?  stageOfCompanyList.find(item => item.ddIndex === project?.stageOfCompanyIndex )?.ddValue
                  : "---"}
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            
          </Stack>
        </Stack>
        <Divider />
        <Stack
          sx={{ marginBottom: "1rem", marginTop: "1rem" }}
          direction="column"
          spacing={2}
        >
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Company
              </p>
              <p className="fs-med-medium p-0 m-0">
                {project?.compTitle !== null && project?.compTitle !== ""
                  ? project?.compTitle
                  : "---"}
              </p>
            </Box>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
              Upfront
              </p>
              <p className="fs-med-medium p-0 m-0">
                {project?.upfront !== null && project?.upfront !== ""
                  ? project?.upfront 
                  : "---"}%
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
              Class
              </p>
              <p className="fs-med-medium p-0 m-0">
                {project?.className !== null && project?.className !== ""
                  ? project?.className
                  : "---"}
              </p>
            </Box>
            
          </Stack>
        </Stack>
        <Divider />
        <Stack
          sx={{ marginBottom: "1rem", marginTop: "1rem" }}
          direction="column"
          spacing={2}
        >
          <Stack direction="row" spacing={2}>
        
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Scheme Type
              </p>
              <p className="fs-med-medium p-0 m-0">
                {(project?.isPrivate ? "Private" : "Public") ?? "---"}
              </p>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
              Due Diligence
              </p>
              <p className="fs-med-medium p-0 m-0">
                {project?.dueDilligence !== null && project?.dueDilligence !== ""
                  ? project?.dueDilligence
                  : "---"}
              </p>
            </Box>
            <Box sx={{ flex: 1 }}>
              <p className="section-label mb-1" style={{ fontSize: "12px" }}>
                Offer Details
              </p>
              <p className="fs-med-medium p-0 m-0">
                {project?.offerDetails !== null && project?.offerDetails !== ""
                  ? project?.offerDetails
                  : "---"}
              </p>
            </Box>
          </Stack>
        </Stack>
      </DrawerLayout>
    </LocalizationProvider>
  )
}

export default SchemeInfo
