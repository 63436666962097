import React, {useState, useEffect} from 'react';
import DialogLayout from '../../components/layout/DialogLayout';
import { useSnackbar } from 'notistack';
import { apiUpdateValuation, apiValuationUpdate } from '../../utils/api/api';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";
import { CircularProgress, IconButton, Stack } from '@mui/material';
import { formatDateTime, toLocalePrice } from '../../utils/StringUtil';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AlertLayout from '../../components/layout/AlertLayout';

const ValuationHistory = ({ open, toggleDialog, scheme, handleSave }) => {
    const [valuationHistoryList, setValuationHistoryList] = useState([]);
    const [editValuationRid, setEditValuationRid] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const [alertLoader, setAlertLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const toggleAlertDialog = () => {
      if(openAlertDialog){
          setOpenAlertDialog(false);
      } else {
          setOpenAlertDialog(true);
      }
    }
    const handleAlertDialog = () => {
      setAlertLoader(true);
      apiUpdateValuation({
        "vuRid": editValuationRid
      })
        .then((data) => {
          setAlertLoader(false);
            enqueueSnackbar(data,SNACKBAR_SUCCESS);
            toggleAlertDialog();
            getValuationHistory();
        })
        .catch((error) => {
          setAlertLoader(false);
            enqueueSnackbar(error,SNACKBAR_ERROR);
        });
    }

    const getValuationHistory = (param = {}) => {
      setIsLoading(true);
        param.oppRid = scheme?.oppRid;
        apiValuationUpdate(param)
            .then((data) => {
              setIsLoading(false);
              setValuationHistoryList(data);
            })
            .catch((error) => {
              setIsLoading(false);
              enqueueSnackbar(error,SNACKBAR_ERROR);
            });
    }

    useEffect(() => {
        getValuationHistory();
    }, [open]);

    const handleValuationDelete = (id) => {
      setEditValuationRid(id);
      toggleAlertDialog();
    }

  return (
    <DialogLayout
        open={open}
        title="Valuation History"
        subtitle={scheme?.oppTitle}
        maxWidth="md"
        closeAction={toggleDialog}
        positiveAction={{label: 'Close', onClick : toggleDialog}}
    >
        <AlertLayout
          open={openAlertDialog}
          title="Are You Sure ?"
          actionLoader={alertLoader}
          closeAction={toggleAlertDialog}
          positiveAction={
              {label: 'Delete', onClick: handleAlertDialog}
          }>
              <p className="fs-large-medium p-0 m-0">This action cannot be undone. This will permanently delete.</p>
        </AlertLayout>

        {isLoading ? <CircularProgress /> : 
        <Stack direction="column" spacing={2}>
          <table className="table table-striped table-borderless w-100 p-0 m-0">
            <thead>
              <tr className="fs-normal-semibold text-center">
                <th scope="col">
                  <p className="mb-2">Date</p>
                </th>
                <th scope="col">
                  <p className="mb-2">Remark</p>
                </th>
                <th scope="col">
                  <p className="mb-2 text-end">Valuation Amount</p>
                </th>
                <th scope="col">
                  <p className="mb-2 text-end">Multiple</p>
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              { valuationHistoryList.map((data, key) => {
                return(
                  <tr className="fs-normal-medium text-center" key={key}>
                    <td className="pt-3">{formatDateTime(data.valuationDate, false)}</td>
                    <td className="pt-3">{data.remark}</td>
                    <td className="pt-3 text-end">{toLocalePrice(data.amount)}</td>
                    <td className="pt-3 text-end">{toLocalePrice(data.multiple)}X</td>
                    <td className="pt-0 text-center">
                        <IconButton color="error" onClick={()=>handleValuationDelete(data.vuRid)}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Stack> }
    </DialogLayout>
  );
}

export default ValuationHistory;