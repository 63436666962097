import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { apiCreateMasterClass, apiUpdateInvestor, apiUpdateMasterClass, companyEdit, saveCompany, uploadImage } from "../../utils/api/api";
import { BASE_URL, SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";
import { CircularProgress } from "@mui/material";
import { enqueueSnackbar, useSnackbar } from 'notistack';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    maxHeight: '80vh',
    bgcolor: "background.paper",
    boxShadow: 24,
    overflow: "auto",
    padding: '0px 20px',
};

const ClassModal = ({ open, onClose, isEdit, masterClass, handleSave }) => {
    const [isLoading, setIsLoading] = useState(false);

    const [cmRid, setCmRid] = useState(null);
    const [name, SetName] = useState('');
    const [hurdleRate, SetHurdleRate] = useState(0.00);
    const [carry, SetCarry] = useState(0.00);
    const [status, SetStatus] = useState(1);

    const handleClose = () => {
        onClose();
    }

    const saveMasterClass = (reqBody) => {
        setIsLoading(true);
        if(isEdit){
            apiUpdateMasterClass(reqBody)
            .then((data) => {
                setIsLoading(false);
                enqueueSnackbar(data,SNACKBAR_SUCCESS);
                handleSave();
            })
            .catch((error) => {
              setIsLoading(false);
              enqueueSnackbar(error,SNACKBAR_ERROR);
            });
        } else {
            apiCreateMasterClass(reqBody)
            .then((data) => {
                setIsLoading(false);
                enqueueSnackbar(data,SNACKBAR_SUCCESS);
                handleSave();
            })
            .catch((error) => {
              setIsLoading(false);
              enqueueSnackbar(error,SNACKBAR_ERROR);
            });
        }
    }

    const onSubmit = () => {
        const reqBody = {
            "cmRid": cmRid,
            "name": name,
            "hurdleRate": parseFloat(hurdleRate),
            "status": parseInt(status),
            "carry": parseFloat(carry)
        }

        if(name.trim() === '' || hurdleRate === '' || carry === ''){
            enqueueSnackbar("Please fill in the required fields", SNACKBAR_WARNING);
        } else {
            saveMasterClass(reqBody);
        }
    }

    useEffect(() => {
        if(isEdit){
            setCmRid(masterClass.cmRid);
            SetName(masterClass.name ? masterClass.name : '');
            SetHurdleRate(masterClass.hurdleRate ? masterClass.hurdleRate : 0.00);
            SetCarry(masterClass.carry ? masterClass.carry : 0.00);
            SetStatus(masterClass.status ? masterClass.status : 0);
        } else {
            setCmRid(null);
            SetName('');
            SetHurdleRate(0.00);
            SetCarry(0.00);
            SetStatus(0);
        }
    }, [masterClass]);

  return (
    <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
            <div className="modal-header">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {isEdit ? 'Edit Class' : 'Add Class'}
                </Typography>
            </div>
            <Stack sx={{ marginBottom: "1rem", marginTop: "2rem" }} direction="column" spacing={3}>
                <TextField
                    sx={{ flex: 1 }}
                    id="outlined-basic"
                    name="name"
                    label="Class Name"
                    variant="outlined"
                    value={name}
                    fullWidth
                    onChange={(e) => SetName(e.target.value)}
                />
                <TextField
                    sx={{ flex: 1 }}
                    id="outlined-basic"
                    name="hurdleRate"
                    label="Hurdle Rate"
                    variant="outlined"
                    value={hurdleRate}
                    fullWidth
                    onChange={(e) => SetHurdleRate(e.target.value)}
                />
                <TextField
                    sx={{ flex: 1 }}
                    id="outlined-basic"
                    name="carry"
                    label="Carry"
                    variant="outlined"
                    value={carry}
                    fullWidth
                    onChange={(e) => SetCarry(e.target.value)}
                />
            </Stack>
            <div className="row mx-0 justify-content-end w-100 mt-3 modal-footer bg-white py-4">
                <button className="btn w-fit px-4 me-1" onClick={() => handleClose()}>
                    Cancel
                </button>
                {
                    isLoading ?
                    (
                        <div
                        className="btn w-fit px-4"
                        >
                        <CircularProgress />
                        </div>
                    ) :
                    (
                        <button
                        className="btn btn-primary w-fit px-4"
                        type="submit"
                        onClick={() => onSubmit()}
                        >
                        Save
                        </button>
                    )
                }
            </div>
        </Box>
    </Modal>
  )
}

export default ClassModal;