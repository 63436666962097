import { Dialog } from '@mui/material';
import { Box, Button, Divider, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { Close } from '@mui/icons-material';

const dialogStyle = {
    width: '100%',
}
const headerStyle = {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 1000,
}
const footerStyle = {
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#fff',
    zIndex: 1000,
}

const AlertLayout = ({open, title, actionLoader, closeAction, positiveAction, children}) => {
    const handlePositiveAction = () => {
        positiveAction.onClick();
    }

  return (
    <Dialog
        open={open}
        onClose={closeAction}
        fullWidth="true"
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <div style={headerStyle}>
            <div className="d-flex justfy-content-between align-items-center py-3 px-4">
                <div className="flex-grow-1">
                  <h3 className="text-primary fs-x-large-semibold p-0 m-0">
                    {title}
                  </h3>
                </div>
                <IconButton onClick={() => closeAction()}>
                  <Close fontSize="medium" color="secondary" />
                </IconButton>
            </div>
            <Divider />
        </div>
        
        <div className="flex-grow-1 p-3">{children}</div>
        
        <div style={footerStyle}>
          <Divider />
          <div className="py-3 px-4">
            {positiveAction ?
            <Button variant="contained" color="secondary" onClick={handlePositiveAction} disabled={actionLoader} fullWidth>
              {positiveAction.label}
            </Button> : '' }
          </div>
        </div>
    </Dialog>
  );
}

export default AlertLayout;