import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { BASE_URL, SNACKBAR_ERROR, SNACKBAR_SUCCESS, TINY_MCE_API_KEY } from "../../utils/constants/constants";
import { useSnackbar } from 'notistack';
import { Editor } from '@tinymce/tinymce-react';
import { apiSchemeDescription, apiSchemeDetails, uploadImage } from '../../utils/api/api';
import Layout from '../../components/layout/Layout';
import { Button } from '@mui/material';

const SchemeDescription = () => {
    const location = useLocation();
    const { schemeRid } = location.state;
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const userType = process.env.REACT_APP_USER_TYPE

    const [scheme, setScheme] = useState({});
    const [description, setDescription] = useState('');

    const handleCancel = () => {
        history.replace(userType === 'ADMIN' ? "/admin/scheme" : "/partner/scheme");
    }

    const getSchemeDetails = (param = {}) => {
        param.oppRid = schemeRid;
        apiSchemeDetails(param)
            .then((data) => {
                setScheme(data);
            })
            .catch((error) => {
                enqueueSnackbar(error, SNACKBAR_ERROR);
            });
    }

    useEffect(() => {
        setDescription(scheme.oppDesc ?? '');
    }, [scheme]);

    useEffect(() => {
        getSchemeDetails();
    }, []);

    const saveSchemeDescription = (param = {}) => {
        param.oppRid = schemeRid;
        param.oppDesc = description;
        apiSchemeDescription(param)
            .then((data) => {
                getSchemeDetails();
                enqueueSnackbar(data, SNACKBAR_SUCCESS);
            })
            .catch((error) => {
                enqueueSnackbar(error, SNACKBAR_ERROR);
            });
    }

    return (
        <Layout title="Scheme">
            <div className="p-4">
                <div className="bg-white p-3 mb-4" style={{ borderRadius: '5px' }}>
                    <div className="d-flex align-items-center">
                        <div className="d-flex flex-grow-1">
                            <h3 className="text-dark fs-large-semibold p-0 m-0">
                                {scheme.oppTitle}
                            </h3>
                        </div>

                        <div className="d-flex gap-2 align-items-center">
                            <Button sx={{ width: 150 }} variant="outlined" onClick={handleCancel}>Close</Button>
                            <Button sx={{ width: 150 }} variant="contained" onClick={() => saveSchemeDescription()} disableElevation>Save</Button>
                        </div>
                    </div>
                </div>

                <Editor
                    value={description}
                    apiKey={TINY_MCE_API_KEY}
                    init={{
                        height: 600,
                        menubar: 'file edit view insert format tools table help',
                        // branding: false,
                        plugins:
                            'advlist autolink lists link image charmap print preview anchor ' +
                            'searchreplace visualblocks code fullscreen insertdatetime media table paste wordcount',
                        toolbar:
                            'undo redo | formatselect | bold italic underline strikethrough | ' +
                            'alignleft aligncenter alignright alignjustify | ' +
                            'bullist numlist outdent indent | ' +
                            'link image media | ' +
                            'code | ' +
                            'table | ' +
                            'removeformat | ' +
                            'fullscreen',
                        images_upload_handler: async blobInfo => {
                            return new Promise((resolve, reject) => {
                                uploadImage(blobInfo.blob())
                                    .then((res) => {
                                        const { path } = res.data;
                                        resolve(`${BASE_URL}/${path}`);
                                    })
                                    .catch((err) => {
                                        enqueueSnackbar(err, SNACKBAR_ERROR);
                                        reject(err);
                                    });
                            });
                        },
                        content_style:
                            'body { font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6; }',
                    }}
                    onEditorChange={(c, e) => setDescription(c)}
                />
            </div>
        </Layout>
    );
}

export default SchemeDescription;