import React, { useState, useEffect } from "react";
import DrawerLayout, { ScoreBoard } from "../../components/layout/DrawerLayout";
import { IconButton, Stack } from "@mui/material";
import { apiDeleteFund, apiWallet, getTransactions } from "../../utils/api/api";
import {
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
} from "../../utils/constants/constants";
import {
  formatDateTime,
  isSubAdmin,
  toLocalePrice,
} from "../../utils/StringUtil";
import { useSnackbar } from "notistack";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AlertLayout from "../../components/layout/AlertLayout";

const PaymentHistory = ({ open, toggleDrawer, investor, handleSave }) => {
  const [invRid, setInvRid] = useState(null);
  const [editFundRid, setEditFundRid] = useState(null);
  const [walletAmount, setWalletAmount] = useState(0.0);
  const [transactionList, setTransactionList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertLoader, setAlertLoader] = useState(false);

  const toggleAlertDialog = () => {
    if (openAlertDialog) {
      setOpenAlertDialog(false);
    } else {
      setOpenAlertDialog(true);
    }
  };
  const handleAlertDialog = () => {
    setAlertLoader(true);
    apiDeleteFund({
      itRid: editFundRid,
      invRid: invRid,
    })
      .then((data) => {
        setAlertLoader(false);
        enqueueSnackbar(data, SNACKBAR_SUCCESS);
        toggleAlertDialog();
        getTransactionList();
        getWallet();
        handleSave();
      })
      .catch((error) => {
        setAlertLoader(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const getWallet = (param = {}) => {
    param.invRid = invRid;
    apiWallet(param)
      .then((data) => {
        setWalletAmount(data.balanceAmt);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTransactionList = (param = {}) => {
    param.invRid = invRid;
    getTransactions(param)
      .then((data) => {
        setTransactionList(data.transactions);
      })
      .catch((error) => {
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  useEffect(() => {
    getTransactionList();
    getWallet();
  }, [invRid]);

  useEffect(() => {
    setInvRid(investor ? investor.invRid || null : null);
  }, [open]);

  const handlePaymentDelete = (id) => {
    setEditFundRid(id);
    toggleAlertDialog();
  };

  return (
    <DrawerLayout
      open={open}
      title="Payment History"
      closeAction={toggleDrawer}
      positiveAction={{ label: "Close", onClick: toggleDrawer }}
    >
      <AlertLayout
        open={openAlertDialog}
        title="Are You Sure ?"
        actionLoader={alertLoader}
        closeAction={toggleAlertDialog}
        positiveAction={{ label: "Delete", onClick: handleAlertDialog }}
      >
        <p className="fs-large-medium p-0 m-0">
          This action cannot be undone. This will permanently delete.
        </p>
      </AlertLayout>

      <Stack
        sx={{ marginBottom: "2rem" }}
        className={transactionList.length === 0 ? "" : "d-none"}
      >
        <p className="fs-large-regular text-center text-muted">No Data</p>
      </Stack>
      <Stack
        sx={{ marginBottom: "1rem" }}
        direction="row"
        spacing={2}
        className={transactionList.length === 0 ? "d-none" : ""}
      >
        <ScoreBoard
          label="Wallet Amount"
          value={toLocalePrice(walletAmount)}
          flex={1}
        />
      </Stack>
      {transactionList.length === 0 ? (
        ""
      ) : (
        <Stack sx={{ marginBottom: "2rem" }} direction="column">
          <table className="table table-striped table-borderless w-100">
            <thead>
              <tr className="fs-normal-semibold text-center">
                <th scope="col">
                  <p className="mb-2">Transacion ID</p>
                </th>
                <th scope="col">
                  <p className="text-center mb-2">Status</p>
                </th>
                <th scope="col">
                  <p className="text-end mb-2">Amount</p>
                </th>
                <th scope="col">
                  <p className="mb-2">Date & Time</p>
                </th>
                <th scope="col">
                  <p className="mb-2">Transacion Type</p>
                </th>
                <th scope="col">
                  <p className="mb-2">Remarks</p>
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {transactionList.map((data, key) => {
                return (
                  <tr className="fs-normal-medium text-center" key={key}>
                    <td className="pt-3">{data.transactionId ? data.transactionId : 'N/A'}</td>
                    <td className="pt-3 text-center">
                      {data.tranStatus === 'SUCCESS'
                        ? "Success"
                        : data.tranStatus === 'FAILED'
                        ? "Failed"
                        : data.tranStatus === 'PENDING'
                        ? "Pending"
                        : ""
                      }
                    </td>
                    <td className="pt-3 text-end">{toLocalePrice(data.amt)}</td>
                    <td className="pt-3">
                      {formatDateTime(data.tranDatetime, data.tranType === 'OFFLINE' ? false : true)}
                    </td>
                    <td className="pt-3">
                      {
                        data.tranType === 'WALLET_TOPUP'
                        ? 'Wallet Topup'
                        : data.tranType === 'OFFLINE'
                        ? 'Offline'
                        : ''
                      }
                    </td>
                    <td className="pt-3">{data.remark}</td>
                    <td className="p-0">
                      {!isSubAdmin() ? (
                        <>
                          {data.tranType === 'OFFLINE'
                            ? 
                              <IconButton
                                color="error"
                                onClick={() => handlePaymentDelete(data.itRid)}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                          : <></>
                          }
                        </>
                      ) : <></>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Stack>
      )}
    </DrawerLayout>
  );
};

export default PaymentHistory;
