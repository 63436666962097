import { TextField,IconButton,InputAdornment } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { authLogin } from "../../utils/api/api";
import { useSnackbar } from 'notistack'
import { setSubAdminType } from "../../utils/LocalStorageUtils";
import { AccountCircle, Visibility, VisibilityOff } from '@mui/icons-material';


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar()
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);



  const handleSubmit = (event) => {
    event.preventDefault();
    if(email === "" || password === ""){
      enqueueSnackbar("Please fill in the required fieds",{variant: "warning"})
      return
    }
    authLogin({ email: email, password: password })
      .then((res) => {
        
        if(res.status){
          if(res.data.jwt === null){
            history.push("/admin/otp", {"transactionToken": res.data.transactionToken, "email": email});
          } else {
            setSubAdminType(res?.data?.user?.userType);
            history.push("/admin/scheme");
          }
        }
      })
      .catch((e) => {
        enqueueSnackbar(e,{variant: "error"})
      });
  };

  return (
    <div className="login-wrapper row mx-0">  
     <div className="bg-primary col-5 m-4 rounded d-flex align-items-center justify-content-center">
        <img src="/logo.png" alt="logo" />
      </div> 
      <div className="col-4 d-flex flex-column p-5 align-items-center justify-content-center">
        <h2 className="w-100 text-start text-primary">Login</h2>
        <form className="w-100" onSubmit={handleSubmit}>
          <div className="mt-4 w-100">
            <TextField
              id="email"
              name="Email"
              label="Email"
              variant="outlined"
              value={email}
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="mt-3">
            <TextField
              id="password"
              name="Password"
              label="Password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{ shrink: true }}
              type={showPassword ? 'text' : 'password'}
              fullWidth
              InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }} 
            />
          </div>
          <button className="btn btn-primary w-100 py-2 mt-4" type="submit">Login</button>
        </form>
      </div>     
    </div>
  );
};

export default Login;
