import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { Divider, Switch } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  apiCreatePartner,
  apiUpdatePartner,
  uploadImage,
} from "../../utils/api/api";
import {
  BASE_URL,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  SNACKBAR_WARNING,
} from "../../utils/constants/constants";
import DrawerLayout from "../../components/layout/DrawerLayout";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  padding: "0px 20px",
};

const PartnerModal = ({ open, onClose, isEdit, partner, handleSave }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [actionLoader, setActionLoader] = useState(false);
  const [partnerRid, setPartnerRid] = useState(null);
  const [partnerFullName, setPartnerFullName] = useState("");
  const [userRid, setUserRid] = useState(null);
  const [partnerEmail, setPartnerEmail] = useState("");
  const [intlPhoneNumber, setIntlPhoneNumber] = useState("");
  const [intlCountryCode, setIntlCountryCode] = useState("");
  const [welcomeEmail, setWelcomeEmail] = useState(false);
  const [logo, setLogo] = useState("");
  const [password, setPassword] = useState("");

  const handleIntlPhoneInput = (value, data) => {
    setIntlPhoneNumber("+" + value);
    setIntlCountryCode("+" + data.dialCode);
  };

  const savePartner = (reqBody) => {
    if (isEdit) {
      setActionLoader(true);
      apiUpdatePartner(reqBody)
        .then((data) => {
          setActionLoader(false);
          enqueueSnackbar(data, SNACKBAR_SUCCESS);
          handleSave();
        })
        .catch((error) => {
          setActionLoader(false);
          enqueueSnackbar(error, SNACKBAR_ERROR);
        });
    } else {
      setActionLoader(true);
      apiCreatePartner(reqBody)
        .then((data) => {
          setActionLoader(false);
          enqueueSnackbar(data, SNACKBAR_SUCCESS);
          handleSave();
        })
        .catch((error) => {
          setActionLoader(false);
          enqueueSnackbar(error, SNACKBAR_ERROR);
        });
    }
  };

  useEffect(() => {
    setIntlPhoneNumber(isEdit ? (partner?.countryCode ? partner?.countryCode : '')  + (partner?.mobile ? partner?.mobile : '') : "");
    setIntlCountryCode(isEdit ? (partner?.countryCode ? partner?.countryCode : '') : "");
    setUserRid(isEdit ? partner?.userRid : null);
    setWelcomeEmail(false);
    if (isEdit) {
      setPartnerRid(partner?.partnerRid);
      setPartnerFullName(partner?.fullName ? partner?.fullName : "");
      setPartnerEmail(partner?.email ? partner?.email : "");
      setLogo(partner?.logo ? partner?.logo : "");
    } else {
      setPartnerRid(null);
      setPartnerFullName("");
      setPartnerEmail("");
      setLogo("");
    }
  }, [open]);

  const onSubmit = () => {
    const countryCode = intlCountryCode;
    const phoneNumberDigits = intlPhoneNumber.replace(countryCode, "");

    const reqBody = {
      partnerMaster: {
        partnerRid: partnerRid,
        userRid: userRid,
        fullName: partnerFullName,
        mobile: phoneNumberDigits,
        email: partnerEmail,
        countryCode: countryCode,
        password: password,
        logo: logo,
        status: 1,
      },
      welcomeEmail: welcomeEmail,
    };

    if (partnerFullName.trim() === "" || partnerEmail.trim() === "") {
      enqueueSnackbar("Please fill in the required fields", SNACKBAR_WARNING);
    } else if (phoneNumberDigits.trim() === "") {
      enqueueSnackbar("Invalid Mobile Number", SNACKBAR_WARNING);
    } else {
      savePartner(reqBody);
    }
  };

  const addImage = (e) => {
    uploadImage(e.target.files[0])
      .then((res) => {
        setLogo(res.data.path);
      })
      .catch((e) => {
        enqueueSnackbar(e, SNACKBAR_ERROR);
      });
  };

  const openExlporer = () => {
    document.getElementById("input-file").click();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DrawerLayout
        drawerWidth={"50%"}
        open={open}
        title={isEdit ? "Edit Partner" : "Add Partner"}
        actionLoader={actionLoader}
        closeAction={onClose}
        negativeAction={{ label: "Cancel", onClick: onClose }}
        positiveAction={{ label: "Save", onClick: onSubmit }}
      >
        <Stack sx={{ marginBottom: "1rem" }} direction="column" spacing={2}>
          <p className="section-label p-0 m-0">PERSONAL DETAILS</p>
          <Stack direction="row" spacing={2}>
            <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              name="fullName"
              label="Partner Name"
              variant="outlined"
              value={partnerFullName}
              fullWidth
              onChange={(e) => setPartnerFullName(e.target.value)}
            />
            <Box sx={{ flex: 1 }}>
              <PhoneInput
                specialLabel="Phone"
                inputStyle={{ width: "100%", height: "100%" }}
                disableDropdown="true"
                country={"in"}
                value={intlPhoneNumber}
                onChange={(v, d) => handleIntlPhoneInput(v, d)}
              />
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              name="email"
              label="Email"
              variant="outlined"
              value={partnerEmail}
              fullWidth
              onChange={(e) => setPartnerEmail(e.target.value)}
            />
            {!isEdit && (
              <TextField
                sx={{ flex: 1 }}
                id="outlined-basic"
                name="Password"
                label="Password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                fullWidth
              />
            )}
          </Stack>
          <Stack>
            <input
              className="d-none"
              id="input-file"
              type="file"
              onClick={(e) => {
                e.target.value = null;
              }}
              onChange={(e) => {
                addImage(e);
              }}
            />
            <p className="section-label mt-4 mb-0">Logo</p>
            <Stack direction="row" sx={{ mt: 2 }}>
              <img
                className="img-preview"
                onClick={() => openExlporer()}
                src={logo ? `${BASE_URL}/${logo}` : "/no-image.jpg"}
              />
            </Stack>
          </Stack>
        </Stack>
        <Divider />
      </DrawerLayout>
    </LocalizationProvider>
  );
};

export default PartnerModal;
