
export function isTextEmpty(text) {

    // function returns true if empty
    
    if(text === null || typeof text !== "string" || text.trim() === "" ){
        return true;
    }

    return false;
}

export function isEitherStringOrNumberValid(text, number){
    // TODO: rename this function

    // returns true if both value is invalid 

    if(isTextEmpty(text) && isNonZeroNumber(number)){
        return true
    }

    return false

}

export function isNonZeroNumber(number){

    if(typeof number !== "number" || number === null){
        return true
    }

    if(number === 0){
        return true
    }

    return false

}

export function containsOnlyNumbers(number) {
    const numberRegex = /^\d+$/;
    const formattedNumber = parseInt(number);

    if(numberRegex.test(formattedNumber)){
        return true;
    }
    return false;
}

export function isValidPAN(number){
    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    return panPattern.test(number);
}
