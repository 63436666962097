import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import RouteCombiner from "./Routes/RouteCombiner";
import Routes, { PartnerRoutes } from "./Routes/Routes";
import PrivateRoute from "./Routes/PrivateRoute";
import PublicRoute from "./Routes/PublicRoute";
import { createTheme, ThemeProvider } from "@mui/material";
import { Route } from "react-router-dom/cjs/react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000'
    },
  },
});
const userType = process.env.REACT_APP_USER_TYPE;

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Route
            path="/"
            exact={true}
            render={({ location }) => <Redirect to={{ pathname: userType === 'ADMIN' ? "/admin/login" : "/partner/login", state: { from: location } }} />
            }
          />
          <RouteCombiner
            routes={ userType === 'ADMIN' ? Routes : PartnerRoutes}
            PrivateRoute={PrivateRoute}
            PublicRoute={PublicRoute}
          />
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
