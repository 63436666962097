import AdminLayout from "../components/layout/AdminLayout";
import PartnerAuthentication from "../pages/login/PartnerAuthentication";
import Login from "../pages/login/Login";
import company from "../pages/company/Company";
import Queries from "../pages/queries/Queries";
import PortfolioCompany from "../pages/portfolio/portfolio-comapnies/PortfolioCompany";
import PortfolioInvestor from "../pages/portfolio/portfolio-investors/PortfolioInvestors";
import Investor from "../pages/investor/Investor";
import Partner from "../pages/partner/Partner";
import Opportunity from "../pages/projects/Opportunity";
import InvestorClass from "../pages/class/InvestorClass";
import SchemeInvestor from "../pages/projects/SchemeInvestor";
import FundMaster from "../pages/fund/FundMaster";
import CompanyUpdates from "../pages/company-updates/CompanyUpdates";
import MassMailer from "../pages/massInvestorMailer/MassMailer";
import OtpPage from "../pages/login/OtpPage";
import SchemeDescription from "../pages/projects/SchemeDescription";
import UnitTransfer from "../pages/unit-transfer/UnitTransfer";
import DematMaster from "../pages/demat/DematMaster";
import { Component } from "react";
import Dashboard from "../pages/admin-dashboard/Dashboard";

export const PartnerRoutes= [
    {
        path: '/partner',
        Component: <div>admin Page</div>,
        Layout: AdminLayout,
        isPrivate: false,
        modules: [
            {
                path: "/login",
                Component: PartnerAuthentication,
                exact: false,
                isPrivate: true
            }
        ]
    },
    {
        path: '/partner',
        Component: <div>admin Page</div>,
        Layout: AdminLayout,
        isPrivate: true,
        modules: [
            {
                path: "/login",
                Component: PartnerAuthentication,
                exact: false,
                isPrivate: true
            },
       
            {
                path: "/scheme",
                Component: Opportunity,
                exact: true,
                isPrivate: true
            },
        
            {
                path: "/scheme/investor",
                Component: SchemeInvestor,
                exact: true,
                isPrivate: true
            },
            {
                path: "/scheme/description",
                Component: SchemeDescription,
                exact: true,
                isPrivate: true
            },
            {
                path: "/investors",
                Component: Investor,
                exact: true,
                isPrivate: true
            },
            {
                path: "/fund",
                Component: FundMaster,
                exact: true,
                isPrivate: true
            }
        ]
    }
]
const Routes = [
    {
        path: '/admin',
        Component: <div>admin Page</div>,
        Layout: AdminLayout,
        isPrivate: false,
        modules: [
            {
                path: "/login",
                Component: Login,
                exact: false,
                isPrivate: true
            },
            {
                path: "/otp",
                Component: OtpPage,
                exact: false,
                isPrivate: true
            },
        
        ]
    },
    {
        path: '/admin',
        Component: <div>admin Page</div>,
        Layout: AdminLayout,
        isPrivate: true,
        modules: [
            {
                path: "/login",
                Component: Login,
                exact: false,
                isPrivate: true
            },
            {
                path: "/otp",
                Component: OtpPage,
                exact: false,
                isPrivate: true
            },
            {
                path: "/dashboard",
                Component: Dashboard,
                exact: true,
                isPrivate: true
            },
            {
                path: "/queries",
                Component: Queries,
                exact: true,
                isPrivate: true
            },
            {
                path: "/portfolio",
                Component: PortfolioCompany,
                exact: true,
                isPrivate: true
            },
            {
                path: "/portfolio/investors",
                Component: PortfolioInvestor,
                exact: true,
                isPrivate: true
            },
            {
                path: "/company",
                Component: company,
                exact: true,
                isPrivate: true
            },
            {
                path: "/scheme",
                Component: Opportunity,
                exact: true,
                isPrivate: true
            },
            {
                path: "/scheme/investor",
                Component: SchemeInvestor,
                exact: true,
                isPrivate: true
            },
            {
                path: "/scheme/description",
                Component: SchemeDescription,
                exact: true,
                isPrivate: true
            },
            {
                path: "/investors",
                Component: Investor,
                exact: true,
                isPrivate: true
            },
            {
                path: "/partners",
                Component: Partner,
                exact: true,
                isPrivate: true
            },
            {
                path: "/demat-master",
                Component: DematMaster,
                exact: true,
                isPrivate: true
            },
            {
                path: "/class",
                Component: InvestorClass,
                exact: true,
                isPrivate: true
            },
            {
                path: "/fund",
                Component: FundMaster,
                exact: true,
                isPrivate: true
            },
            {
                path: "/company-updates",
                Component: CompanyUpdates,
                exact: true,
                isPrivate: true
            },
            {
                path: "/massInvestorMailer",
                Component: MassMailer,
                exact:true,
                isPrivate:true
            },
            {
                path: "/unit-transfer",
                Component: UnitTransfer,
                exact: true,
                isPrivate: true
            }
        ]
    }
]


export default Routes;