import React from 'react'
import Layout from '../../components/layout/Layout'
import PieChart from './graphs/PieChart'


const Dashboard = () => {
    return (
        <Layout title="Dashboard">
            <h1>Dashboard</h1>
            <PieChart/>
        </Layout>

    )
}

export default Dashboard