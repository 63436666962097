import { Autocomplete, Badge, Box, Button, Checkbox, Chip, CircularProgress, Divider, FormControlLabel, IconButton, Radio, RadioGroup, Stack, Switch, TextField, Tooltip, tooltipClasses } from "@mui/material";
import { apiDeleteMCA, apiEnableInvestorLogin, apiGetSchemeLaunch, apiMca, apiSaveFunds, apiSaveMCA, apiSchemeLaunch, getDataDictionaryValues, getInvestors, uploadFile } from "../../utils/api/api";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { BASE_URL, DD_TYPE_MCA_DEL_REASON, DD_TYPE_SOURCE_TYPE, SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";
import { useSnackbar } from 'notistack';
import { formatDateTime, toLocalePrice, toLocalePriceNoDecimal } from "../../utils/StringUtil";
import DialogLayout from "../../components/layout/DialogLayout";
import { useState } from "react";

const InvestorLogin = ({ open, toggleDialog, investor, handleSave }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleInvestorEnabled = (value) => {
        setIsLoading(true);
        apiEnableInvestorLogin({
            "invRid": investor?.invRid,
            "enable": value ? 1 : 0
        })
        .then((data) => {
            setIsLoading(false);
            enqueueSnackbar(data,SNACKBAR_SUCCESS);
            handleSave();
            toggleDialog();
        })
        .catch((error) => {
            setIsLoading(false);
            enqueueSnackbar(error,SNACKBAR_ERROR);
        });
    }

  return (
    <DialogLayout
        open={open}
        title="Investor Login"
        subtitle={investor?.fullName}
        maxWidth="xs"
        closeAction={toggleDialog}
        positiveAction={{label: 'Cancel', onClick : toggleDialog}}
    >
        { isLoading ? <CircularProgress /> :
        <div className="d-flex gap-3 flex-column">
            <Box sx={{ flex: 1 }}>
                <p className="section-label mb-1" style={{fontSize: '12px'}}>Login Enabled</p>
                <p className="fs-med-medium p-0 m-0">
                    <Switch name="mcaStatus" color="secondary" checked={investor?.loginEnabled} onChange={(e) => handleInvestorEnabled(e.target.checked)} />
                </p>
            </Box>
            <Box sx={{ flex: 1 }}>
                <p className="section-label mb-1" style={{fontSize: '12px'}}>Enabled On</p>
                <p className="fs-med-medium p-0 m-0">
                {
                    investor?.loginEnabledDatetime
                    ? formatDateTime(investor?.loginEnabledDatetime, true)
                    : '---'
                }
                </p>
            </Box>
        </div> }
    </DialogLayout>
  )
}

export default InvestorLogin