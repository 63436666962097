import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { getPartners, apiCompanyUpdateDetails, apiCreateInvestor, apiMca, apiSaveCompanyUpdate, apiSaveMassEmail, apiTags, apiUpdateCompanyUpdate, apiUpdateInvestor, getCompanies, getDataDictionaryValues, getInvestments, getMasterClass, getOpportunity, getTransactions, uploadFile, uploadImage, uploadMultipleFile } from "../../utils/api/api";
import { BASE_URL, DD_TYPE_AUTHOR, DD_TYPE_BANK_TYPE, DD_TYPE_FORM_TYPE, DD_TYPE_INVESTOR_TYPE, DD_TYPE_SOURCE_TYPE, DD_TYPE_TAGS, SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING, TINY_MCE_API_KEY } from "../../utils/constants/constants";
import { RemoveCircle } from "@mui/icons-material";
import styles from "../../pages/projects/Project.module.css";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DrawerLayout, { ScoreBoard } from "../../components/layout/DrawerLayout";
import {
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Skeleton,
    Tooltip,
    Autocomplete,
    Chip,
    Badge,
    CircularProgress,
    OutlinedInput,
    Checkbox,
    ListItemText,

} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useSnackbar } from "notistack";
import { Editor } from '@tinymce/tinymce-react';
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { formatDateTime, isSubAdmin } from "../../utils/StringUtil";
import AlertLayout from "../../components/layout/AlertLayout";

const criteriaList = [
    { title: 'Pan No', criteriaRid: 1 },
    { title: 'Email', criteriaRid: 2 },
    { title: 'Mobile No', criteriaRid: 3 },
    { title: 'Inv Code', criteriaRid: 4 },
]

const filterList = [
    { title: 'Active Investor', filterRid: 1 },
    { title: 'MCA Signed Investor', filterRid: 2 },
    { title: 'Onboarded Investor', filterRid: 3 },
    // {title: 'Filter By Partner', filterRid:4},
]
const MassMailerDrawer = ({ open, onClose, handleSave }) => {
    const [meRid, setMeRid] = useState(null);
    const [criteriaRid, setCriteriaRid] = useState(null);
    const [filterRid, setFilterRid] = useState(null);
    const [subject, setSubject] = useState(null);
    const [mailBody, setMailBody] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [actionLoader, setActionLoader] = useState(false);
    const [formType, setFormType] = useState(null);
    const [formTypeValue, setFormTypeValue] = useState(null);
    const [formTypeList, setformTypeList] = useState([]);
    const [filterPartner, setFilterPartner] = useState([]);

    const [filterValues, setFilterValues] = useState({
        isActive: false,
        mcaStatus: null,
        partnerRid: "",

    });
    const [partnerList, setPartnerList] = useState([]);
    
    const handleAutoCompleteFS = (value, type) => {
        value = value.trim();
        if (type === DD_TYPE_FORM_TYPE) {
            const ddData = formTypeList.find((item) =>
                item.ddValue.trim() === value
            );
            if (ddData) {
                setFormTypeValue(null);
                setFormType(ddData.ddIndex);

            } else {
                setFormTypeValue(value);
                setFormType(null);
            }
        }
    }

    const openDocExplorer = () => {
        document.getElementById('input-file').click();
    }
    const getPartnersList = () => {
        setPartnerList([]);
        getPartners()
            .then((res) => {
                setPartnerList(res.partnerMasterList);
            })
            .catch((e) => {
                enqueueSnackbar(e, SNACKBAR_ERROR);
            });
    };

    const uploadAttachments = (e) => {
        setIsFileLoading(true);
        uploadMultipleFile(e.target.files)
            .then((res) => {
                setIsFileLoading(false);
                setAttachments((prevAttachments) => [
                    ...prevAttachments,
                    ...res.data.uploadedFiles
                ]);
            })
            .catch((e) => {
                setIsFileLoading(false);
                enqueueSnackbar(e, SNACKBAR_ERROR);
            });
    };

    const deleteAttachment = (index) => {
        const newList = [...attachments];
        newList.splice(index, 1);
        setAttachments(newList);
    }
    const saveMassEmail = (reqBody) => {
        apiSaveMassEmail(reqBody)
            .then((data) => {
                setActionLoader(false);
                enqueueSnackbar(data, SNACKBAR_SUCCESS);
                handleSave();
            })
            .catch((error) => {
                setActionLoader(false);
                enqueueSnackbar(error, SNACKBAR_ERROR);
            });
    }
    const getDataDictionary = () => {
        getDataDictionaryValues()
            .then((data) => {
                const formTypes = data.filter((item) =>
                    item.ddType === DD_TYPE_FORM_TYPE
                );
                setformTypeList(formTypes);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const handleSelectionChange = (selectedOptions) => {
        const newValues = {
            isActive: null,
            mcaStatus: null,
            partnerRid: filterValues.partnerRid,
        };

        selectedOptions.forEach((option) => {
            if (option.filterRid === 1) {
                newValues.isActive = true;
            } else if (option.filterRid === 2) {
                newValues.mcaStatus = 1;
            } else if (option.filterRid === 3) {
                newValues.mcaStatus = 0;
            }
        });

        setFilterValues(newValues);
    };

    const getSelectedFilters = () => {
        return filterList.filter((option) => {
            if (option.filterRid === 1 && filterValues.isActive) return true;
            if (option.filterRid === 2 && filterValues.mcaStatus === 1) return true;
            if (option.filterRid === 3 && filterValues.mcaStatus === 0) return true;
            if (option.filterRid === 4 && filterValues.partnerRid.length > 0) return true;
            return false;
        });
    };

    const onSubmit = () => {
        const reqBody = {
            formType: formTypeValue,
            formDdIndex: formType,
            subject: subject,
            mailBody: mailBody,
            criteria: criteriaRid,
            attachments: attachments,
            filterObj: filterValues,
        }
        if (subject === null || mailBody === null) {
            enqueueSnackbar("Please fill in the required fields", SNACKBAR_WARNING);
        } else if (criteriaRid === 0 && 
            !filterValues.isActive && 
            filterValues.mcaStatus === null && 
            filterValues.partnerRid === "") {
            enqueueSnackbar("Please select criteria or filter", SNACKBAR_WARNING);
        }else if(criteriaRid ==1 && (filterValues.isActive || 
            filterValues.mcaStatus ||
            filterValues.partnerRid)){
            enqueueSnackbar("You can't select criteria and filter together",SNACKBAR_WARNING)
        }else if (isFileLoading) {
            enqueueSnackbar("File is uploading!! Please wait", SNACKBAR_WARNING);
        } else if (attachments.length === 0) {
            enqueueSnackbar("Please upload document", SNACKBAR_ERROR);
        }else {
            saveMassEmail(reqBody);
        }
    }
    useEffect(() => {
        getDataDictionary();
        setSubject(null);
        setMailBody(null);
        setCriteriaRid(0);
        setFilterValues({ isActive: false,
            mcaStatus: null,
            partnerRid: "",})
        setAttachments([]);
        getPartnersList();
    }, [open]);

    return (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <DrawerLayout
                open={open}
                disableEnforceFocus={true}
                title={'Mass Mailer Investor'}
                actionLoader={actionLoader}
                closeAction={onClose}
                negativeAction={
                    { label: 'Cancel', onClick: onClose }
                }
                positiveAction={
                    { label: 'Save', onClick: onSubmit }
                }
            >

                <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
                    <TextField
                        sx={{ flex: 1.5 }}
                        id="outlined-basic"
                        name="subject"
                        label="Subject"
                        variant="outlined"
                        value={subject}
                        fullWidth
                        onChange={(e) => setSubject(e.target.value)}
                    />

                    <Autocomplete
                        freeSolo
                        sx={{ flex: 1 }}
                        id="outlined-basic"
                        variant="outlined"
                        options={formTypeList}
                        getOptionLabel={(option) => option.ddValue}
                        value={formTypeList.find((option) => option.ddIndex === formType) || null}
                        fullWidth
                        onInputChange={(e, v) => {
                            handleAutoCompleteFS(v, DD_TYPE_FORM_TYPE);
                        }}
                        renderInput={(params) => <TextField {...params} label="Form Type" />}
                    />

                </Stack>
                <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
                    <Autocomplete
                        sx={{ flex: 0.5 }}
                        id="outlined-basic"
                        options={criteriaList}
                        getOptionLabel={(option) => option.title}
                        value={criteriaList.find((option) => option.criteriaRid === criteriaRid) || null}
                        fullWidth
                        onChange={(e, v) => setCriteriaRid(v ? v.criteriaRid : null)}
                        renderInput={(params) => <TextField {...params} label="Criteria" />}
                        renderOption={(props, option) => (
                            <li {...props} key={option.criteriaRid}>{option.title}</li>
                        )}
                    />
                    <Autocomplete
                        sx={{ flex: 0.5 }}
                        multiple
                        id="filter-autocomplete"
                        options={filterList} // Exclude the "Filter by Partner" option from this list
                        getOptionLabel={(option) => option.title}
                        value={getSelectedFilters()}
                        onChange={(event, selectedOptions) => handleSelectionChange(selectedOptions)}
                        renderInput={(params) => <TextField {...params} label="Filter" />}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.filterRid}>
                                <Checkbox
                                    checked={selected}
                                    style={{ marginRight: 8 }}
                                />
                                {option.title}
                            </li>
                        )}
                    />
                    <FormControl sx={{ width: 250 }} size="large">
                        <InputLabel id="demo-multiple-checkbox-label">Filter By Partner</InputLabel>
                        <Select
                            multiple
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={filterPartner}
                            onChange={(e) => {
                                const newPartners = e.target.value;
                                setFilterPartner(newPartners);

                                // Update filterValues with new partnerRid value
                                setFilterValues((prevValues) => ({
                                    ...prevValues,
                                    partnerRid: newPartners.join(","),
                                }));
                            }}
                            input={<OutlinedInput label="Filter By Partner" />}
                            renderValue={(selected) => `${selected.length} Selected`}
                        >
                            {partnerList.map((data, key) => (
                                <MenuItem key={key} value={data.partnerRid}>
                                    <Checkbox checked={filterPartner.indexOf(data.partnerRid) > -1} />
                                    <ListItemText primary={data.fullName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack sx={{ marginBottom: "1rem" }} direction="column" spacing={2}>
                    <Editor
                        value={mailBody}
                        apiKey={TINY_MCE_API_KEY}
                        init={{
                            height: 400,
                            menubar: 'file edit view insert format tools table help',
                            // branding: false,
                            plugins:
                                'advlist autolink lists link image charmap print preview anchor ' +
                                'searchreplace visualblocks code fullscreen insertdatetime media table paste wordcount',
                            toolbar:
                                'undo redo | formatselect | bold italic underline strikethrough | ' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent | ' +
                                'link image media | ' +
                                'code | ' +
                                'table | ' +
                                'removeformat | ' +
                                'fullscreen',
                            images_upload_handler: async blobInfo => {
                                return new Promise((resolve, reject) => {
                                    uploadImage(blobInfo.blob())
                                        .then((res) => {
                                            const { path } = res.data;
                                            resolve(`${BASE_URL}/${path}`);
                                        })
                                        .catch((err) => {
                                            enqueueSnackbar(err, SNACKBAR_ERROR);
                                            reject(err);
                                        });
                                });
                            },
                            content_style:
                                'body { font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6; }',
                        }}
                        onEditorChange={(c, e) => setMailBody(c)}
                    />
                    {/* <div>{description}</div> */}
                </Stack>


                <Stack sx={{ marginBottom: "1rem", marginTop: "1rem" }} direction="column" spacing={2}>
                    <p className="section-label p-0 m-0">Upload Documents</p>
                    <input
                        className="d-none"
                        id="input-file"
                        type="file"
                        multiple
                        accept=".pdf"
                        onClick={(e) => {
                            e.target.value = null;
                        }}
                        onChange={(e) => {
                            uploadAttachments(e);
                        }}
                    />
                    <Stack sx={{ flex: 1, alignItems: 'center', flexWrap: 'wrap', gap: 1 }} direction="row">
                        {attachments?.map((data, key) => {
                            return (
                                <Tooltip title={data.actualFileName} key={key}>
                                    <Badge
                                        badgeContent={<RemoveCircle fontSize="10" onClick={() => deleteAttachment(key)} />}
                                        color="error">
                                        <AttachFileIcon color="secondary" />
                                    </Badge>
                                </Tooltip>
                            );
                        })}
                        {isFileLoading ? <CircularProgress color="secondary" /> : 
                        <IconButton color="secondary" onClick={() => openDocExplorer()}>
                            <AddIcon />
                        </IconButton>}
                    </Stack>
                </Stack>
            </DrawerLayout>
        </LocalizationProvider>
    );
};

export default MassMailerDrawer;
