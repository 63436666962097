import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import { RECORD_DISPLAY_LIMIT } from "../../utils/constants/constants";
import { Chip, CircularProgress, Skeleton, Tooltip, tooltipClasses } from "@mui/material";
import { apiDematTableInvestors, getDematMasterTable } from "../../utils/api/api";
import { styled } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    border: '1px solid #000',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
  },
}));

export const DematInvestors = ({ dematInvestorList, showTableLoading }) => {
  return (
    <div className="d-flex flex-column overflow-y-auto" style={{ minWidth: '300px', maxHeight: '500px' }}>
      {
        showTableLoading
          ? <CircularProgress />
          : <>
            {
              dematInvestorList?.map((investor, index) => (
                <div className="d-flex align-items-center px-2 py-1" key={index}>
                  <AccountCircleIcon color="primary" fontSize="large" />
                  <div className="d-flex flex-grow-1 flex-column ms-2">
                    <div>
                      <span className="fs-med-medium text-dark">{investor?.fullName}</span>
                      <span className="fs-med-medium ms-1">
                        <Chip
                          label={investor?.dematCreditDate ? 'CREDITED' : 'PENDING'}
                          size="small"
                          color={investor?.dematCreditDate ? 'success' : 'warning'}
                          variant="outlined"
                          sx={{
                            fontSize: '0.7rem',
                            height: '20px',
                            padding: '4px',
                          }}
                        />
                      </span>
                    </div>
                    <span className="fs-small-regular text-dark">{investor?.email}</span>
                    <span className="fs-small-medium text-dark">{investor?.invCode}</span>
                  </div>
                </div>
              ))
            }
          </>
      }
    </div>
  );
}

const DematMaster = () => {
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dematList, setDematList] = useState([]);
  const [dematDataList, setDematDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [dematInvestors, setDematInvestors] = useState([]);
  const [showTableLoader, setShowTableLoader] = useState(false);

  const getDematTableInvestors = async (params) => {
    setShowTableLoader(true);
    try {
      const res = await apiDematTableInvestors({
        sd: params?.dpType,
        oppRid: params?.oppRid,
        cmRid: params?.cmRid,
      });
      setDematInvestors(res);
      setShowTableLoader(false);
    } catch (error) {
      console.error("Error fetching demat data:", error);
      setShowTableLoader(false);
    }
  }

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const getDematList = async () => {
    const params = {};
    setIsLoading(true);
    try {
      const data = await getDematMasterTable(params);
      setDematList(data.tableColumn);
      setDematDataList(data.tableData);
      setTotalRecords(data.records);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching demat data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDematList();
  }, [page]);

  return (
    <Layout title="Demat Master">
      <div className="d-flex flex-column bg-white overflow-hidden">
        <div className="d-flex flex-grow-1 bg-white py-2 px-3 overflow-y-scroll " style={{ borderRadius: "5px" }}>
          {isLoading ? (
            [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
              <Skeleton
                animation="wave"
                key={index}
                style={{ marginBottom: 6 }}
                variant="rectangle"
                height={40}
              />
            ))
          ) : (
            <div className="overflow-x-scroll">
              {dematList?.length > 0 ? (
                <table
                  className="table table-striped table-borderless w-100 position-relative"
                  id="order-table"
                >
                  <thead>
                    <tr className="fs-normal-semibold text-center sticky-table-row">
                      <th className="sticky-table-cell" scope="col" style={{ minWidth: "250px" }}>
                        <p className="m-0">Scheme Names</p>
                      </th>
                      {dematList.map((item, key) => (
                        <th
                          key={key}
                          scope="col"
                          style={{ minWidth: "150px" }}
                          className="text-center"
                        >
                          <p className={`${item.dpType === "ESCROW" ? 'text-danger' : ''} m-0`}>
                            {item.name} {item.dpType}
                          </p>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-table-blue">
                    {dematDataList.map((data, index) => (
                      <tr key={index} className="fs-normal-medium text-center">
                        <td className="sticky-table-cell">{data.schemeName}</td>
                        {data.cells.map((cellData, cellIndex) => (
                          <td className="text-center" key={cellIndex}>
                            {
                              cellData?.dpType === null
                                ? <span>-</span>
                                : (
                                  cellData?.value === 0
                                    ? <span className={`${cellData.dpType === "ESCROW" ? 'text-danger' : ''}`}>
                                      {cellData.value}
                                    </span>
                                    : <CustomTooltip title={
                                      <DematInvestors dematInvestorList={dematInvestors} showTableLoading={showTableLoader} />
                                    } arrow>
                                      <span className={`${cellData.dpType === "ESCROW" ? 'text-danger' : ''}`} style={{ cursor: 'pointer' }} onMouseEnter={() => getDematTableInvestors(cellData)}>
                                        {cellData.value}
                                      </span>
                                    </CustomTooltip>
                                )
                            }
                            {/* <CustomTooltip title={
                                <div className="d-flex gap-0 flex-column w-100 p-5 bg-primary">
                                  <p>1</p>
                                </div>
                              } arrow>
                                {cellData.dpType === "ESCROW" ? (
                                  <span className="text-danger">
                                    {cellData.value}
                                  </span>
                                ) : cellData.dpType === null ? (
                                  <span>-</span>
                                ) : (
                                  <span>{cellData.value}</span>
                                )}
                              </CustomTooltip> */}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No Data..</p>
              )}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default DematMaster;
